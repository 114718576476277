import { Builder } from "builder-pattern";
import { GroupIdentity, GroupIdentityType, Identity } from "../../general/identity";

export namespace Branch {
    export class Data{
        displayName: string;
        categories: string[];
        domain: string;
        responses: Responses;
        adminTeam: GroupIdentity;
        displayMode: DisplayMode;
    }

    export type DisplayMode = "Fullsize" | "Default";

    export interface Responses {
        greetings: {
            openUpGreeting_1: string,
            openUpGreeting_2: string
        };
        inProgressResponses: {
            multipleAnswersFoundResponse: string;
            firstAnswerNotFoundResponse: string;
            secondAnswerNotFoundResponse: string;
        };
        feedbackFollowUps: {
            positiveFeedback: string;
            firstNegativeFeedback: string;
            secondNegativeFeedback: string;
        }
    }

    const defaultResponse: Responses = {
        greetings: {
            openUpGreeting_1: "Hi, I'm Lincoln, you can ask me about working with AWS Legal",
            openUpGreeting_2: "How can I help you today?"
        },
        inProgressResponses: {
            multipleAnswersFoundResponse: "Got it - did you mean...",
            firstAnswerNotFoundResponse: "Sorry, I could not find an answer, please try asking again",
            secondAnswerNotFoundResponse: "Sorry, no luck, try our wiki",
        },
        feedbackFollowUps: {
            positiveFeedback: "Thank you for the positive feedback. Ask me another question",
            firstNegativeFeedback: "Thank you for the feedback. Try asking again?",
            secondNegativeFeedback: "Sorry, no luck, try our wiki",
        }
    }

    export class Factory {
        static create(params: {
            displayName: string;
            categories?: string[];
            domain: string;
            adminTeam: GroupIdentity;
            displayMode: DisplayMode;
            responses?: Responses;
        }) {
            const builder = Builder<Branch.Data>(new Branch.Data())
                .displayName(params.displayName)
                .domain(params.domain)
                .categories([])
                .adminTeam(params.adminTeam)
                .displayMode(params.displayMode)
                .responses(defaultResponse);
            if (params.categories) {
                builder.categories(params.categories);
            }
            if (params.responses) {
                builder.responses(params.responses);
            }
            return builder.build();
        }

        static update(data: Branch.Data, params: {
            responses?: Responses;
            categories?: string[]
        }) {
            const builder = Builder<Branch.Data>(data);
            if (params.categories) {
                builder.categories(params.categories);
            }
            if (params.responses) {
                builder.responses(params.responses);
            }
            return builder.build();
        }

        static updateResponse(data: Responses, params: {
            greetings?: {
                openUpGreeting_1?: string,
                openUpGreeting_2?: string
            };
            inProgressResponses?: {
                multipleAnswersFoundResponse?: string;
                firstAnswerNotFoundResponse?: string;
                secondAnswerNotFoundResponse?: string;
            };
            feedbackFollowUps?: {
                positiveFeedback?: string;
                firstNegativeFeedback?: string;
                secondNegativeFeedback?: string;
            }
        }): Responses {
            const builder = Builder<Responses>(data);
            if (!!params.greetings) {
                const greetingBuilder = Builder<{
                    openUpGreeting_1: string;
                    openUpGreeting_2: string;
                }>(data.greetings);

                if (!!params.greetings.openUpGreeting_1) {
                    greetingBuilder.openUpGreeting_1(params.greetings.openUpGreeting_1);
                }
                if (!!params.greetings.openUpGreeting_2) {
                    greetingBuilder.openUpGreeting_2(params.greetings.openUpGreeting_2);
                }
                builder.greetings(greetingBuilder.build());
            }
            if (!!params.feedbackFollowUps) {
                const feedbackBuilder = Builder<{
                    positiveFeedback: string;
                    firstNegativeFeedback: string;
                    secondNegativeFeedback: string;
                }>(data.feedbackFollowUps);

                if (params.feedbackFollowUps.firstNegativeFeedback) {
                    feedbackBuilder.firstNegativeFeedback(params.feedbackFollowUps.firstNegativeFeedback);
                }
                if (params.feedbackFollowUps.secondNegativeFeedback) {
                    feedbackBuilder.secondNegativeFeedback(params.feedbackFollowUps.secondNegativeFeedback);
                }
                if (params.feedbackFollowUps.positiveFeedback) {
                    feedbackBuilder.positiveFeedback(params.feedbackFollowUps.positiveFeedback);
                }

                builder.feedbackFollowUps(feedbackBuilder.build());
            }
            if (!!params.inProgressResponses) {
                const inProgressBuilder = Builder<{
                    multipleAnswersFoundResponse: string;
                    firstAnswerNotFoundResponse: string;
                    secondAnswerNotFoundResponse: string;
                }>(data.inProgressResponses);

                if (!!params.inProgressResponses.firstAnswerNotFoundResponse) {
                    inProgressBuilder.firstAnswerNotFoundResponse(params.inProgressResponses.firstAnswerNotFoundResponse);
                }
                if (!!params.inProgressResponses.secondAnswerNotFoundResponse) {
                    inProgressBuilder.secondAnswerNotFoundResponse(params.inProgressResponses.secondAnswerNotFoundResponse);
                }
                if (!!params.inProgressResponses.multipleAnswersFoundResponse) {
                    inProgressBuilder.multipleAnswersFoundResponse(params.inProgressResponses.multipleAnswersFoundResponse);
                }

                builder.inProgressResponses(inProgressBuilder.build());
            }
            return builder.build();
        }
    }
}