import React = require("react")
import { Entity, QuestionAnswerPair } from "@amzn/lincoln-chatbot-domain"
import { CardsProps, Input } from "@amzn/awsui-components-react"

export const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 Q&As' },
    { value: 30, label: '30 Q&As' },
    { value: 50, label: '50 Q&As' }
]

export const CUSTOM_PREFERENCE_OPTIONS = [
    { value: 'cards', label: 'Cards' },
    { value: 'table', label: 'Table' }
]

export const CONTENT_SELECTOR_OPTIONS = [
    {
        label: 'Q&A properties',
        options: [
            { id: 'questionContent', label: 'Question', editable: true, visible: true},
            { id: 'categoryTag', label: 'Category', editable: true, visible: true},
            { id: 'OptionalTag', label: 'Tags', editable: true, visible: true},
        ]
    }
]

export const CARD_DEFINITION: CardsProps.CardDefinition<Entity<QuestionAnswerPair.Data>> = {
    header: item => "Question&Answer Pair",
    sections: [
        {
            id: 'questionContent',
            header: 'Question',
            content: (item: Entity<QuestionAnswerPair.Data>) => item.payload.question
        },
        {
            id: 'answer',
            header: "Answer",
            content: (item: Entity<QuestionAnswerPair.Data>) => {
                if (item.payload.answer.length < 100) {
                    return item.payload.answer;
                } else return item.payload.answer.substring(0, 90) + "...[Truncated]"
            }
        },
        {
            id: 'categoryTag',
            header: "Category",
            content: (item: Entity<QuestionAnswerPair.Data>) => item.payload.category
        },
        {
            id: 'OptionalTag',
            header: 'Tags',
            content: (item: Entity<QuestionAnswerPair.Data>) => {
                if (!item.payload.tags) return "";
                return item.payload.tags.join(', ')
            }
        },
    ]
}

export interface EditorProps{
    onChange: (value: string, index: number) => void;
    readOnly: boolean;
}

export const CATEGORY_EDITOR_DEFINITION = (props: EditorProps) => [
    {
        label: 'Category',
        control: (category, itemIndex) => (
            <Control 
                value={category} 
                placeholder='Enter category' 
                onChange={({ detail: { value } }) => props.onChange(value, itemIndex)}
                readOnly={props.readOnly} 
            />
        )
    },
    {
        label: 'Description',
        control: (category) => (
            <Control 
                value='' 
                placeholder='Enter description for this category (Feature in developing)' 
                onChange={({ detail: { value } }) => { alert("Editing not implemented") }} 
                readOnly={props.readOnly} 
            />
        )
    }
]

export const OPTIONALTAD_EDITOR_DEFINITION = (props: EditorProps) => [
    {
        label: 'tag',
        control: (tag, itemIndex) => <Control
                                        value={tag}
                                        placeholder='Enter optional tag'
                                        onChange={({ detail: {value } }) => props.onChange(value, itemIndex)}
                                        readOnly={props.readOnly}
                                    />
    }
]

function Control({ value, placeholder, readOnly, onChange}) {
    return (
        <Input
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            readOnly={readOnly}
        />
    )
}