import { Builder } from "builder-pattern";
import { Identity } from "./identity";

export class IOFactory {
    static createInput<T>(
        data: T,
        identity: Identity
    ): APIInput<T> {
        return Builder<APIInput<T>>()
            .data(data)
            .identity(identity)
            .build();
    }

    static createOutput<T>(data: T): APIOutput<T> {
        return Builder<APIOutput<T>>()
            .data(data)
            .build();
    }

    static createErrorOutput(err: ErrorOutput): APIOutput<any> {
        return Builder<APIOutput<any>>()
            .err(err)
            .build();
    }
}

export interface APIInput<T> {
    data: T;
    identity: Identity
}

export interface APIOutput<T> {
    data?: T;
    err?: ErrorOutput;
}

export class ErrorOutput {
    message: string;
    code: "RuntimeError" | "ValidationError";
}

export class RuntimeErrorOutput extends ErrorOutput {
   static construct(msg: string) {
       return Builder<RuntimeErrorOutput>()
            .code("RuntimeError")
            .message(msg)
            .build();
   }
}

export class ValidationErrorOutput extends ErrorOutput {
    static construct(msg: string) {
        return Builder<RuntimeErrorOutput>()
             .code("ValidationError")
             .message(msg)
             .build();
    }
}