import { Builder } from "builder-pattern";
import { Entity, Ref } from "../../domains/entity";
import { Query } from "../../domains/payload-object/query";
import { APIInput, APIOutput } from "../../general/api";

export namespace IQuery {
    export interface API {
        create(input: APIInput<CreateQuery.Input>): Promise<APIOutput<Entity<Query.Data>>>;
        update(input: APIInput<UpdateQuery.Input>): Promise<APIOutput<Entity<Query.Data>>>;
    }

    export namespace CreateQuery {
        export class Input {
            branch?: string;
            history: Query.History[];

            private constructor() {}

            static construct(params: {
                history: Query.History[],
                branch?: string;
            }): Input {
                return Builder<Input>(new Input())
                    .history(params.history)
                    .branch(params.branch)
                    .build();
            }
        }
    }

    export namespace UpdateQuery {
        export class Input {
            original: Ref;
            newData: Query.Data;

            private constructor() {}

            static construct(params: {
                original: Ref;
                newData: Query.Data;
            }): Input {
                return Builder<Input>(new Input())
                    .original(params.original)
                    .newData(params.newData)
                    .build();
            }
        }
    }
}