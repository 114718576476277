import { SideNavigationProps, SideNavigation } from "@amzn/awsui-components-react";
import React = require("react");


export const navHeader = { text: "Lincoln Management Hub", href: "#/admin"}
export const navItems: SideNavigationProps.Item[] = [
    { type: 'divider' },
    {
        type: 'link',
        text: 'Documentation',
        href: 'https://w.amazon.com/bin/view/ALTAR/tools/Lincoln/',
        external: true
    },
    {
        type: 'link',
        text: 'Report a Bug',
        href: 'https://issues.amazon.com/issues/create?template=2cd87a3a-25a9-46e9-acc8-0c9839ce5a4f',
        external: true
    }
];
    
export function Navigation() {
    const [activeHref, setActiveHref] = React.useState('#/admin')

    return (
        <SideNavigation 
            items={navItems} 
            header={navHeader} 
            activeHref={activeHref} 
        />
    )
}