import { MsgSender } from "../components/chatbot/common"

export class Conversation {
    private sendBy: MsgSender
    private content: string
    private clickable: boolean
    private pendingAnswer:string
    private showFeedback: boolean
    private inquiryId: string
    private isVoice: boolean
    private voiceAnswer: string
    private isFeedbackSubmitted: boolean
    private shouldShowLogo: boolean

    constructor(sendBy: MsgSender) {
        this.sendBy = sendBy;
        this.isVoice = false;
        this.clickable = false;
        this.showFeedback = false;
        this.isFeedbackSubmitted = false;
        this.shouldShowLogo = true;
    }

    public shouldDisplayLogo() {
        return this.shouldShowLogo
    }

    public disableLogo() {
        this.shouldShowLogo = false;
    }

    public onSubmitFeedback() {
        this.isFeedbackSubmitted = true
    }

    public setContent(content: string){
        this.content = content
    }

    public setVoiceAnswer(voiceString: string) {
        this.isVoice = true;
        this.voiceAnswer = voiceString
    }

    public addPendingAnswer(pendingAnswer: string) {
        this.clickable = true;
        this.pendingAnswer = pendingAnswer
    }

    public playable() {
        return this.isVoice
    }

    public displayFeedback() {
        this.showFeedback = true
    }

    public setInquiryId(inquiryId: string) {
        this.inquiryId = inquiryId
    }

    public getVoiceAnswer(){
        return this.voiceAnswer
    }

    public isSendbyUser() {
        return this.sendBy === MsgSender.User
    }

    public isClickable() {
        return this.clickable
    }

    public getInquiryId() {
        return this.inquiryId
    }

    public getPendingAnswer() {
        return this.pendingAnswer
    }

    public getContent() {
        return this.content
    }

    public shouldDisplayFeedback() {
        return this.showFeedback
    }

    public feedbackSubmitted() {
        return this.isFeedbackSubmitted
    }

}