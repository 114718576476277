import { Form, Button, Header, SpaceBetween } from "@amzn/awsui-components-react"
import React = require("react")

export enum DisplayMode {
    View,
    Edit,
    Create,
}

export const QADetailBaseFormContent = (props: {
    content,
    onSubmit,
    mainButtonStatus,
    displayMode,
    onCancelClick,
    onReset,
    errorText?
}) => {
    return (
        <div>
            <Form
                header ={
                    <Header
                        variant='h1'  
                        actions={
                            <SpaceBetween direction='horizontal' size='xs'>
                                { 
                                    props.displayMode === DisplayMode.View? 
                                    <Button loading={props.mainButtonStatus} variant='primary' onClick={props.onSubmit} >
                                        Edit
                                    </Button> : null
                                }
                                {props.displayMode === DisplayMode.Edit? (
                                    <>
                                        <Button onClick={props.onCancelClick} variant="link">Cancel</Button>
                                        <Button onClick={props.onReset}>
                                            Reset Values
                                        </Button>
                                    </>
                                ): null}
                                {
                                    props.displayMode === DisplayMode.Edit && 
                                    <Button loading={props.mainButtonStatus} variant='primary' onClick={props.onSubmit} >
                                        Save Updates
                                    </Button>
                                }
                            </SpaceBetween>
                        }
                    >
                        {props.displayMode === DisplayMode.Create? "Create Q&A pairing": "Q&A pairing"}
                    </Header>
                }
                actions={
                    props.displayMode === DisplayMode.Create && 
                    <SpaceBetween direction='horizontal' size='l'>
                        <Button variant='link' onClick={props.onCancelClick}>Cancel</Button>
                        <Button loading={props.mainButtonStatus} variant='primary' onClick={props.onSubmit} >
                            Create
                        </Button> 
                    </SpaceBetween>
                }
                errorText={props.errorText ? props.errorText : null}
            >
                {props.content}
                <br/>
            </Form>
        </div>
    )
}
