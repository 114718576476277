import axios from 'axios'

export interface ClientConfig {
    lincoln: {
        branchApi: string;
        kendraApi: string;
        questionAnswerPairApi: string;
        queryApi: string;
    }
    cognito: {
        userPoolName: string;
        clientId: string;
        domain: string;
        signInRedirectUrl: string;
        signOutRedirectUrl: string;
        responseType: string;
        scope: string[]
    };
    stage: Stage;
    awsRegion: string;
}

export enum Stage {
    Beta = 'beta',
    Gamma = 'gamma',
    Prod = 'prod',
}

export class ClientConfigFactory {
    public static async getConfig(): Promise<ClientConfig> {
        let configFile = "config/config.json"
        // if (process.env.STAGE !== "any"){
        //     configFile = "stage-config/" + process.env.STAGE + "/config/config.json"
        // }
        // console.log(configFile)
        const origin = location.origin
        const pureOrigin = origin.substring(8)
        if (!pureOrigin.startsWith('0')) {
            // this is in non-local env
            const tokens = pureOrigin.split('.')
            switch (tokens[0]) {
                case 'beta':
                    configFile = "stage-config/beta/config/config.json"
                    break;
                case 'gamma':
                    configFile = "stage-config/gamma/config/config.json"
                    break;
                default:
                    configFile = "stage-config/prod/config/config.json"
                    break;
            }
        }
        else {
            //in local env
            configFile = "stage-config/dev/config/config.json"
        }
        const configPath = `${location.origin}/${configFile}`;
        const res = await fetch(configPath)
        const data = await res.json()

        const config: ClientConfig = {
            lincoln: {
                branchApi: data['lincoln']['branchApiUrl'],
                kendraApi: data['lincoln']['kendraApiUrl'],
                questionAnswerPairApi: data['lincoln']['questionAnswerPairApiUrl'],
                queryApi: data['lincoln']['queryApiUrl'],
            },
            cognito: {
                userPoolName: data['cognito']['userPoolName'],
                clientId: data['cognito']['clientId'],
                domain: data['cognito']['domain'],
                signInRedirectUrl: data['cognito']['signInRedirectUrl'],
                signOutRedirectUrl: data['cognito']['signOutRedirectUrl'],
                responseType: data['cognito']['responseType'],
                scope: data['cognito']['scope'],
            },
            stage: data['stage'],
            awsRegion: data['awsRegion']
        }

        return config
    }
}