import { SpaceBetween } from "@amzn/awsui-components-react";
import { Branch, Entity, QuestionAnswerPair } from "@amzn/lincoln-chatbot-domain";
import React = require("react");
import { useHistory } from "react-router";
import { APIModel } from "../../model/general-models";
import { QuestionAnswerPairModel } from "../../model/question-answer-pair-model";
import { CustomHooks } from "../../utils/async-runner";
import { AdditionalInfoComps } from "./additional-info-comps";
import { AnswerComps } from "./answer-comps";
import { DisplayMode, QADetailBaseFormContent } from "./common";
import { QuestionComps } from "./question-comps";

export const QuestionAnswerPairDetailContent = (props: {
    entityBranch: Entity<Branch.Data>;
    entity: Entity<QuestionAnswerPair.Data>
}) => {
    const history = useHistory();
    const updateQAInputState = QuestionAnswerPairModel.UI.UpdateState.use({
        originalEntity: props.entity
    });

    const [displayMode, setDisplayMode] = React.useState<DisplayMode>(DisplayMode.View);

    const updateQAPairRunner = CustomHooks.useRunner(
        APIModel.updateQuestionAnswerPair
    );

    const onSubmit = () => {
        if (displayMode === DisplayMode.Edit) {
            updateQAPairRunner.submitRun(
                QuestionAnswerPairModel.UI.UpdateState.toInput(updateQAInputState)
            );
        } else {
            setDisplayMode(DisplayMode.Edit);
        }
    }
    
    return (
        <QADetailBaseFormContent
            displayMode={displayMode}
            onSubmit={onSubmit}
            onReset={() => updateQAInputState.reset()}
            onCancelClick={() => setDisplayMode(DisplayMode.View)}
            mainButtonStatus={updateQAPairRunner.status === "Running"}
            content={
                <React.Fragment>
                {displayMode === DisplayMode.View &&
                    <SpaceBetween size="l">
                        <QuestionComps.View qa={props.entity.payload}/>
                        <AnswerComps.View qa={props.entity.payload}/>
                        <AdditionalInfoComps.View qa={props.entity.payload} branch={props.entityBranch.payload}/>
                    </SpaceBetween>
                }
                {displayMode === DisplayMode.Edit &&
                    <SpaceBetween size="l">
                        <QuestionComps.Update state={updateQAInputState}/>
                        <AnswerComps.Update state={updateQAInputState}/>
                        <AdditionalInfoComps.Update state={updateQAInputState} branch={props.entityBranch.payload}/>
                    </SpaceBetween>
                }
                </React.Fragment>
            }
        />
    )
}