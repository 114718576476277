import * as React from "react";
import '../../style/body.scss'
import { UIModel } from "../../model/general-models";
import { ChatbotOpenStatus } from "./common";

interface UserInputProps {
    chatbotOpenState: UIModel.FieldState<ChatbotOpenStatus>
    isLoading: boolean
    isProcessingAnswer: boolean;
    userInput: string;
    onInputChange(userInput: string): void;
    //onEnterPress(userInput: string): void
    onSearch: () => void;
}


export class UserInputPanel extends React.Component<UserInputProps, {}> {
    private textarea
    constructor(props: any) {
        super(props)
        this.state = {}
    }

    handleChange(event) {
        this.props.onInputChange(event.target.value)
    }

    handleSubmit(event) {
        debugger;
        if (event.key !== 'Enter') {
            return 
        }
        if (!this.props.isProcessingAnswer) {
            const userInput = this.props.userInput
            this.props.onSearch()
            event.preventDefault()
        }
    }

    focusTextarea() {
        this.textarea.focus()
    }


    componentDidUpdate(prevProps: Readonly<UserInputProps>, prevState: Readonly<{}>, snapshot?: any): void {
        this.focusTextarea();
    }

    render() {
        const textInputLoading = this.props.isLoading || this.props.isProcessingAnswer
        if (this.props.chatbotOpenState.value === ChatbotOpenStatus.FULLSIZE) {
            return <div className="fullsize-inputarea">
                <div>
                    <textarea
                        onKeyPress={this.handleSubmit.bind(this)}
                        value={this.props.userInput}
                        onChange={(event) => this.props.onInputChange(event.target.value)}
                        rows={2}
                        wrap='soft'
                        required={true}
                        ref={(el) => {this.textarea = el}}
                        placeholder="Type here and start asking"
                    />
                </div>
            </div>
        } else {
            return (
                <div>
                    <div className={`input-div ${this.props.chatbotOpenState.value === ChatbotOpenStatus.EXPANDED ? 'inputdiv-expand': ''}`}>
                        <textarea className={`chatbot-textarea ${this.props.chatbotOpenState.value === ChatbotOpenStatus.EXPANDED ? 'textarea-expand': ''}`} 
                            onKeyPress={this.handleSubmit.bind(this)}
                            value={this.props.userInput}
                            onChange={(event) => this.props.onInputChange(event.target.value)}
                            rows={2}
                            wrap='soft'
                            required={true}
                            ref={(el) => {this.textarea = el}}
                        />
                        <span
                            className={`lincoln-textarea-placeholder ${this.props.chatbotOpenState.value === ChatbotOpenStatus.EXPANDED ? 'lincoln-textarea-placeholder-expand': ''}`} 
                            onClick={() => this.focusTextarea()}
                            style={{
                                position: 'absolute',
                                bottom: this.props.chatbotOpenState.value === ChatbotOpenStatus.EXPANDED ?'35px':'17px',
                                right: this.props.chatbotOpenState.value === ChatbotOpenStatus.EXPANDED?'95px':'95px'
                            }}
                        >Type here and start asking</span>
                    </div>
                    {/* <RecorderBtn submit={this.props.speechToText} isProcessingVoice={recordingButtonLoading} expanded={this.props.expanded}/> */}
                </div>
            );
        }
    }   
}