import { Builder } from "builder-pattern";
import { Ref, Entity } from "../../domains/entity";
import { Branch } from "../../domains/payload-object/branch";
import { APIInput, APIOutput } from "../../general/api";
import { GroupIdentity } from "../../general/identity";

export namespace IBranch {
    export interface API {
        load(input: APIInput<Ref>): Promise<APIOutput<Entity<Branch.Data>>>;
        getAllBranches(input: APIInput<undefined>): Promise<APIOutput<Entity<Branch.Data>[]>>;
        createBranch(input: APIInput<CreateBranch.Input>): Promise<APIOutput<Entity<Branch.Data>>>;
        updateBranch(input: APIInput<UpdateBranch.Input>): Promise<APIOutput<Entity<Branch.Data>>>;
    }

    export namespace CreateBranch {
        export class Input {
            name: string;
            displayName: string;
            domain: string;
            adminTeam: GroupIdentity;
            displayMode: Branch.DisplayMode;
            
            private constructor() {}

            static construct(params: {
                name: string;
                displayName: string;
                domain: string;
                adminTeam: GroupIdentity;
                displayMode: Branch.DisplayMode
            }): Input {
                return Builder<Input>(new Input())
                    .name(params.name)
                    .displayName(params.displayName)
                    .domain(params.domain)
                    .adminTeam(params.adminTeam)
                    .build();
            }
        }
    }

    export namespace UpdateBranch {
        export class Input {
            original: Ref;
            newData: Branch.Data;

            private constructor() {}

            static construct(params: {
                original: Ref;
                newData: Branch.Data;
            }): Input {
                return Builder<Input>(new Input())
                    .original(params.original)
                    .newData(params.newData)
                    .build();
            }
        }
    }
}