import { Textarea, Header, SpaceBetween, Box, TextContent, Container, FormField} from "@amzn/awsui-components-react";
import { QuestionAnswerPair } from "@amzn/lincoln-chatbot-domain";
import React = require("react");
import { QuestionAnswerPairModel } from "../../model/question-answer-pair-model";
import ReactMarkdown from "react-markdown";

export namespace AnswerComps {
    export const Create = (props: {
        state: QuestionAnswerPairModel.UI.CreateState
    }) => {
        return (
            <Container
                className='custom-screenshot-hide'
                header={<Header variant='h2'>Answer information</Header>}>
                <SpaceBetween direction='horizontal' size='s'>
                    <FormField
                        label={
                            <span id='answer-creation-label'>
                                Text answer
                            </span>
                        }
                        description={
                            <TextContent >
                                <small>
                                    Lincoln will respond with the following text answer in the chat when the user uses keyboard entry 
                                    and the above question is the best match to the user’s question. Content can be in plain text or using 
                                    <a href='https://www.markdownguide.org/basic-syntax/' target='_blank' rel="noopener noreferrer"> markdown style</a>. 
                                    Click on the Preview button to preview how the answer will display.
                                </small>
                            </TextContent>
                        }
                        stretch={true}>
                        <Textarea
                            onChange={({ detail }) => props.state.textAnswerField.setValue(detail.value)}
                            placeholder='Type text answer content here'
                            value={props.state.textAnswerField.value}
                        />
                    </FormField>
                    <FormField
                        label={
                            <span id='answer-creation-label'>
                                Text answer preview
                            </span>
                        }
                        stretch={true}>
                        <ReactMarkdown>
                            {props.state.textAnswerField.value}
                        </ReactMarkdown>
                    </FormField>
                </SpaceBetween>
            </Container>
        )
    }

    export const View = (props: {
        qa: QuestionAnswerPair.Data
    }) => {
        return (
            <Container
                className='custom-screenshot-hide'
                header={<Header variant='h2'>Answer information</Header>}
            >
                <SpaceBetween direction='vertical' size='s'>
                    <FormField
                        label={
                            <span id='answer-creation-label'>
                                Text answer
                                <Box variant='span' margin={{ left: 'xs' }}>
                                </Box>
                            </span>
                        }
                        description={
                            <TextContent >
                                <small>
                                    Lincoln will respond with the following text answer in the chat when the user uses keyboard entry 
                                    and the above question is the best match to the user’s question. Content can be in plain text or using 
                                    <a href='https://www.markdownguide.org/basic-syntax/' target='_blank' rel="noopener noreferrer"> markdown style</a>. 
                                    Click on the Preview button to preview how the answer will display.
                                </small>
                            </TextContent>
                        }
                        stretch={true}>
                        <ReactMarkdown>
                            {props.qa.answer}
                        </ReactMarkdown>
                    </FormField>
                </SpaceBetween>
            </Container>
        )
    }

    export const Update = (props: {
        state: QuestionAnswerPairModel.UI.UpdateState
    }) => {
        return (
            <Container
                className='custom-screenshot-hide'
                header={<Header variant='h2'>Answer information</Header>}
            >
                <SpaceBetween direction='horizontal' size='s'>
                    <FormField
                        label={
                            <span id='answer-creation-label'>
                                Text answer
                                <Box variant='span' margin={{ left: 'xs' }}>
                                    {/* <InfoLink id='question-creation-info-link' onFollow={() => this.props.updateTools(1)} /> */}
                                </Box>
                            </span>
                        }
                        errorText={props.state.newEntityDataField.value.answer.length === 0 ? "Text Answer content is required" : null}
                        description={
                            <TextContent >
                                <small>
                                    Lincoln will respond with the following text answer in the chat when the user uses keyboard entry 
                                    and the above question is the best match to the user’s question. Content can be in plain text or using 
                                    <a href='https://www.markdownguide.org/basic-syntax/' target='_blank' rel="noopener noreferrer"> markdown style</a>. 
                                    Click on the Preview button to preview how the answer will display.
                                </small>
                            </TextContent>
                        }
                        stretch={true}
                    >
                        <Textarea
                            onChange={(event) => props.state.update({answer: event.detail.value})}
                            placeholder='Type text answer content here'
                            value={props.state.newEntityDataField.value.answer}
                        />
                        <ReactMarkdown>
                            {props.state.newEntityDataField.value.answer}
                        </ReactMarkdown>
                    </FormField>
                </SpaceBetween>
            </Container>
        )
    }
}