import { Header } from "@amzn/awsui-components-react";
import { useEffect } from "react";
import React = require("react")

export function getCounterString(totalItems, selectedItems) {
    return selectedItems && selectedItems.length > 0 ? `(${selectedItems.length}/${totalItems.length})` : `(${totalItems.length})`;
}

export const TableHeader = (props: {
    title: string
    selectedItems: Array<any>
    totalItems: Array<any>
    actionButtons: JSX.Element
}) => {
    const [counter, setCounter] = React.useState<string>("");

    useEffect(() => {
        console.log("2");
        setCounter(getCounterString(props.totalItems, props.selectedItems));
    }, [props.totalItems.length, props.selectedItems.length])

    return (
        <div>
            <Header
                variant="h2"
                counter={`(${counter})`}
                actions={props.actionButtons}>
                    {props.title}
            </Header>
        </div>
    )
}