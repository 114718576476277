import React = require("react");
import ChatCircle from "../../components/chatbot/chat-circle";
import '../../style/body.scss'
import { AppContext } from "../../utils/context";
import { Builder } from "builder-pattern";
import { ChatbotLoadingPage } from "./ChatbotLoadingPage";
import { CustomHooks } from "../../utils/async-runner";
import { APIModel, UIModel } from "../../model/general-models";
import { EntityType, Ref } from "@amzn/lincoln-chatbot-domain";
import { Spinner } from "@amzn/awsui-components-react";
import { ChatbotOpenStatus } from "../../components/chatbot/common";
import { ChatbotCoreComp } from "../../components/chatbot/chatbot-core";

class AuthBypassState {
    authenticated: boolean;
    authError: boolean;
    initialLoadCompleted: boolean;
    timeoutId: any;
    authenticationRequested: boolean;
    openChatbotOnceAuthenticated: boolean;

    constructor() {
        this.authError = false;
        this.authenticated = false;
        this.initialLoadCompleted = false;
        this.timeoutId = null;
        this.authenticationRequested = false;
        this.openChatbotOnceAuthenticated = false;
    }

    static setAuthError(state: AuthBypassState, authError: boolean) {
        return Builder<AuthBypassState>(state).authError(authError).build()
    }

    static setAuthenticated(state: AuthBypassState, authenticated: boolean) {
        return Builder<AuthBypassState>(state).authenticated(authenticated).build()
    }

    static setOpenChatbotOnceAuthenticated(state: AuthBypassState, open: boolean) {
        return Builder<AuthBypassState>(state).openChatbotOnceAuthenticated(open).build();
    }

    static setTimeoutId(state: AuthBypassState, timeoutId: any) {
        return Builder<AuthBypassState>(state).timeoutId(timeoutId).build()
    }

    static setInitialLoadCompleted(state: AuthBypassState, initialLoadCompleted: boolean) {
        return Builder<AuthBypassState>(state).initialLoadCompleted(initialLoadCompleted).build()
    }

    static setAuthenticationRequested(state: AuthBypassState, authenticationRequested: boolean) {
        return Builder<AuthBypassState>(state).authenticationRequested(authenticationRequested).build()
    }
}

export const Chatbot = (props: {
    authBypassMode: boolean;
    branchId: string;
}) => {
    const context = React.useContext(AppContext);
    const [authBypassState, setAuthBypassState] = React.useState<AuthBypassState>(new AuthBypassState());
    const chatbotOpenState = UIModel.FieldState.use<ChatbotOpenStatus>({
        initialValue: ChatbotOpenStatus.CLOSED
    })

    const checkAuth = () => {
        console.log("Still checking authentication...");

        context.currentUser()
            .then((cognitoUser) => {
                setAuthBypassState(
                    AuthBypassState.setAuthenticated(authBypassState, true)
                );
                if (authBypassState.openChatbotOnceAuthenticated) {
                    chatbotOpenState.setValue(ChatbotOpenStatus.OPEN);
                }
                localStorage.removeItem("AuthPageClosed");
                clearTimeout(authBypassState.timeoutId);
            })
            .catch((err) => {
                console.log("Err: ", err);
                const isAuthPageClosed = localStorage.getItem("AuthPageClosed");
                if (isAuthPageClosed === "true") {
                    setAuthBypassState(
                        AuthBypassState.setAuthError(authBypassState, true)
                    );
                    return;
                }
                setAuthBypassState(
                    AuthBypassState.setTimeoutId(authBypassState, setTimeout(checkAuth, 1000))
                );
            })
            .finally(() => {
                setAuthBypassState( 
                    AuthBypassState.setInitialLoadCompleted(authBypassState, true) 
                )
            });
    };

    const loadBranchRunner = CustomHooks.useRunner(
        APIModel.loadBranch
    );

    React.useEffect(() => {
        if (props.authBypassMode) {
            checkAuth();
        }
        loadBranchRunner.submitRun(
            Builder<Ref>().id(props.branchId).type(EntityType.Branch).build()
        );
    }, []);

    React.useEffect(() => {
        if (loadBranchRunner.status === "OK") {
            if (loadBranchRunner.runState.data.payload.displayMode === "Fullsize") {
                chatbotOpenState.setValue(ChatbotOpenStatus.FULLSIZE);
            }
        }
    }, [loadBranchRunner.status]);

    const onChatbotExpand = () => {
        if (props.authBypassMode) {
            if (authBypassState.authenticated) {
                chatbotOpenState.setValue(ChatbotOpenStatus.OPEN);
                return;
            }
            if (!authBypassState.authenticated && !authBypassState.authenticationRequested) {
                setAuthBypassState(
                    AuthBypassState.setOpenChatbotOnceAuthenticated(authBypassState, true)
                );
                openAuthPageInNewBrowserTab();
            }
        } else {
            chatbotOpenState.setValue(ChatbotOpenStatus.OPEN);
        }
    }

    const openAuthPageInNewBrowserTab = () => {
        const origin = location.origin
        setAuthBypassState(
            AuthBypassState.setAuthenticationRequested(authBypassState, true)
        );
        window.open(`${ origin }/#/auth`, '_blank')
    };

    return (
        <div>
            {(loadBranchRunner.status === "New" || loadBranchRunner.status === "Running") && (
                <Spinner/>
            )}
            {(loadBranchRunner.status === "OK") && (
                <div>
                    {((props.authBypassMode && authBypassState.authenticated) || !props.authBypassMode) && loadBranchRunner.status === "OK" &&
                        <ChatbotCoreComp
                            branch={loadBranchRunner.runState.data}
                            chatbotOpenState={chatbotOpenState}
                        />
                    }
                    {props.authBypassMode && !authBypassState.authenticated && authBypassState.authenticationRequested && 
                        <ChatbotLoadingPage error={authBypassState.authError}/>
                    }
                    {loadBranchRunner.runState.data.payload.displayMode !== "Fullsize" && <ChatCircle 
                        chatbotOpenState={chatbotOpenState}
                        onCloseAllWindow={() => chatbotOpenState.setValue(ChatbotOpenStatus.CLOSED)}
                        onExpandChatWindow={onChatbotExpand}
                    />}
                </div>
            )}
        </div>
    )
}