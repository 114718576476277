import { FormField, Textarea, Header, SpaceBetween, Box, Container, Button, Modal, TextContent } from "@amzn/awsui-components-react"
import { KendraService, QuestionAnswerPair } from "@amzn/lincoln-chatbot-domain"
import React = require("react")
import { APIModel } from "../../model/general-models"
import { QuestionAnswerPairModel } from "../../model/question-answer-pair-model"
import { CustomHooks } from "../../utils/async-runner"

export namespace QuestionComps {
    export const DupCheckModal = (props: {
        results: KendraService.KendraSearch.Output;
        onDismiss: () => void;
    }) => {
        let body = (
            <TextContent>
                <p><small>Questions that are a near exact match to this question will be displayed</small></p>
                <p>No duplicate questions found</p>
            </TextContent>
        )
        if (props.results.totalNumberOfResults > 0) {
            body = (
                <TextContent>
                    <p><small>Questions that are a near exact match to this question will be displayed</small></p>
                    <p>Duplicate question(s) were found:</p>
                    <ul>
                        {props.results.queryResults.map((result) => {
                            return (
                                <li>
                                    <p>Question: {result.question}</p>
                                    <p>Answer: {result.answer}</p>
                                </li>
                            )
                        })}
                    </ul>
                </TextContent>
            )
        }

        return (
            <Modal
                onDismiss={props.onDismiss}
                visible={true} 
                header="Duplicate questions"
                footer={<Box float='right'><Button variant='link' onClick={props.onDismiss}>Okay</Button></Box>}>
                {body}
            </Modal>
        )
    }

    export const Create = (props: {
        state: QuestionAnswerPairModel.UI.CreateState;
    }) => {
        const [dupModalState, setDupModalState] = React.useState<boolean>(false);
        const duplicationCheckRunner = CustomHooks.useRunner(
            APIModel.search
        )

        const duplicationCheck = () => {
            duplicationCheckRunner.submitRun(KendraService.KendraSearch.Input.construct({
                queryText: props.state.questionContentField.value,
                branch: props.state.branchRefField.value
            }))
        }

        React.useEffect(() => {
            if (duplicationCheckRunner.status === "OK") {
                setDupModalState(true);
            }
        }, [duplicationCheckRunner.status]);

        return (
            <React.Fragment>
                {dupModalState && <DupCheckModal onDismiss={() => setDupModalState(false)} results={duplicationCheckRunner.runState.data}/>}
                <Container
                    className='custom-screenshot-hide'
                    header={<Header variant='h2'>Question information</Header>}>
                    <SpaceBetween direction='vertical' size='s'>
                        <FormField
                            label={
                                <span id='question-creation-label'>
                                    Question
                                    <Box variant='span' margin={{ left: 'xs' }}>
                                    </Box>
                                </span>
                            }
                            errorText={
                                (props.state.questionContentField.value.length > 200 && "Question length cannot exceed 200 character limit (including commas, periods and spaces)") ||
                                (props.state.questionContentField.value.length === 0? "Question content is required": null)
                            }
                            description='Lincoln will search against questions and find the best match to the user’s question. Questions should be in plain text, avoiding acronyms. '
                            stretch={true}>
                            <Textarea
                                onChange={({ detail }) => props.state.questionContentField.setValue(detail.value)}
                                placeholder='Type question content here'
                                value={props.state.questionContentField.value}/>
                        </FormField>
                        <Button
                            loading={duplicationCheckRunner.status === "Running"} 
                            onClick={duplicationCheck}>
                            Find similar questions
                        </Button>
                    </SpaceBetween>
                </Container>
            </React.Fragment>
        )
    }

    export const View = (props: {
        qa: QuestionAnswerPair.Data
    }) => {
        return (
            <Container
                className='custom-screenshot-hide'
                header={<Header variant='h2'>Question information</Header>}>
                <SpaceBetween direction='vertical' size='s'>
                    <FormField
                        label={
                            <span id='question-creation-label'>
                                Question
                                <Box variant='span' margin={{ left: 'xs' }}>
                                </Box>
                            </span>
                        }
                        description='Lincoln will search against questions and find the best match to the user’s question. Questions should be in plain text, avoiding acronyms. '
                        stretch={true}>
                        <Textarea
                            readonly={true}
                            value={props.qa.question}/>
                    </FormField>
                </SpaceBetween>
            </Container>
        )
    }

    export const Update = (props: {
        state: QuestionAnswerPairModel.UI.UpdateState
    }) => {
        const duplicationCheckRunner = CustomHooks.useRunner(
            APIModel.search
        )
        const [dupModalState, setDupModalState] = React.useState<boolean>(false);

        const duplicationCheck = () => {
            duplicationCheckRunner.submitRun(KendraService.KendraSearch.Input.construct({
                queryText: props.state.newEntityDataField.value.question,
                branch: props.state.newEntityDataField.value.branch,
            }))
        }

        React.useEffect(() => {
            if (duplicationCheckRunner.status === "OK") {
                setDupModalState(true);
            }
        }, [duplicationCheckRunner.status]);

        return (
            <React.Fragment>
                {dupModalState && <DupCheckModal onDismiss={() => setDupModalState(false)} results={duplicationCheckRunner.runState.data}/>}
                <Container
                    className='custom-screenshot-hide'
                    header={<Header variant='h2'>Question information</Header>}>
                    <SpaceBetween direction='vertical' size='s'>
                        <FormField
                            label={
                                <span id='question-creation-label'>
                                    Question
                                    <Box variant='span' margin={{ left: 'xs' }}>
                                    </Box>
                                </span>
                            }
                            errorText={
                                (props.state.newEntityDataField.value.question.length > 200 && "Question length cannot exceed 200 character limit (including commas, periods and spaces)") ||
                                (props.state.newEntityDataField.value.question.length === 0? "Question content is required": null)
                            }
                            description='Lincoln will search against questions and find the best match to the user’s question. Questions should be in plain text, avoiding acronyms. '
                            stretch={true}>
                            <Textarea
                                onChange={({ detail }) => props.state.update({ question: detail.value })}
                                placeholder='Type question content here'
                                value={props.state.newEntityDataField.value.question}/>
                        </FormField>
                        <Button
                            loading={duplicationCheckRunner.status === "Running"} 
                            onClick={duplicationCheck}>
                            Check for duplicates
                        </Button>
                    </SpaceBetween>
                </Container>
            </React.Fragment>
        )
    }
}