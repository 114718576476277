import {
    IBranch,
    APIOutput,
    Entity,
    Branch,
    IOFactory,
    IQuestionAnswerPair,
    QuestionAnswerPair,
    IQuery,
    KendraService,
    Ref,
    Query
} from "@amzn/lincoln-chatbot-domain";
import { Builder } from "builder-pattern";
import React = require("react");
import { AppContextValue } from "../utils/context-value";

export namespace UIModel {
    export class FieldState<T> {
        value: T;
        setValue: (v: T) => void;
        reset: () => void;

        static use<T>(props: {
            initialValue?: T
        }) {
            const [val, setVal] = React.useState<T>(props.initialValue);

            const reset = () => {
                setVal(props.initialValue);
            }

            return Builder<FieldState<T>>().value(val).setValue(setVal).reset(reset).build();
        }
    }
}

export namespace APIModel {
    export async function createBranch(
        context: AppContextValue,
        input: IBranch.CreateBranch.Input
    ): Promise<APIOutput<Entity<Branch.Data>>> {
        const identity = await context.getIdentity();
        const output = await context
            .getBranchAPI()
            .create(
                IOFactory.createInput(input, identity)
            );
        return output;
    }

    export async function loadAllBranches (
        context: AppContextValue,
        input: {}
    ): Promise<APIOutput<Entity<Branch.Data>[]>> {
        const identity = await context.getIdentity();
        const output = await context
            .getBranchAPI()
            .loadAll(
                IOFactory.createInput(input, identity)
            );
        return output;
    }

    export async function createQuestionAnswerPair(
        context: AppContextValue,
        input: IQuestionAnswerPair.AddQuestionAnswerPair.Input
    ): Promise<APIOutput<Entity<QuestionAnswerPair.Data>>> {
        const identity = await context.getIdentity();
        const output = await context
            .getQuestionAnswerPairAPI()
            .create(
                IOFactory.createInput(input, identity)
            );
        return output;
    }

    export async function updateQuestionAnswerPair(
        context: AppContextValue,
        input: IQuestionAnswerPair.UpdateQuestionAnswerPair.Input
    ): Promise<APIOutput<Entity<QuestionAnswerPair.Data>>> {
        const identity = await context.getIdentity();
        const output = await context
            .getQuestionAnswerPairAPI()
            .update(
                IOFactory.createInput(input, identity)
            );
        return output;
    }

    export async function createQuery(
        context: AppContextValue,
        input: IQuery.CreateQuery.Input
    ): Promise<APIOutput<Entity<Query.Data>>> {
        const identity = await context.getIdentity();
        const output = await context
            .getQueryAPI()
            .create(
                IOFactory.createInput(input, identity)
            );
        return output;
    }

    export async function search(
        context: AppContextValue,
        input: KendraService.KendraSearch.Input
    ): Promise<APIOutput<KendraService.KendraSearch.Output>> {
        const identity = await context.getIdentity();
        const output = await context
            .getKendraSearchAPI()
            .query(
                IOFactory.createInput(input, identity)
            );
        return output;
    }

    export async function loadQuestionAnswerPairsByBranch(
        context: AppContextValue,
        input: Ref
    ): Promise<APIOutput<Entity<QuestionAnswerPair.Data>[]>> {
        const identity = await context.getIdentity();
        const output = await context
            .getQuestionAnswerPairAPI()
            .loadByBranch(
                IOFactory.createInput(input, identity)
            );
        return output;
    }

    export async function deleteQAPair(
        context: AppContextValue,
        input: Ref
    ): Promise<APIOutput<Entity<QuestionAnswerPair.Data>>> {
        const identity = await context.getIdentity();
        const output = await context
            .getQuestionAnswerPairAPI()
            .delete(
                IOFactory.createInput(input, identity)
            );
        return output;
    }
    
    export async function loadQuestionAnswerPair(
        context: AppContextValue,
        input: Ref
    ): Promise<APIOutput<Entity<QuestionAnswerPair.Data>>> {
        const identity = await context.getIdentity();
        const output = await context
            .getQuestionAnswerPairAPI()
            .load(
                IOFactory.createInput(input, identity)
            );
        return output;
    }

    export async function loadBranch(
        context: AppContextValue,
        input: Ref
    ): Promise<APIOutput<Entity<Branch.Data>>> {
        const identity = await context.getIdentity();
        const output = await context
            .getBranchAPI()
            .load(
                IOFactory.createInput(input, identity)
            );
        return output;
    }

    export async function updateBranch(
        context: AppContextValue,
        input: IBranch.UpdateBranch.Input
    ): Promise<APIOutput<Entity<Branch.Data>>> {
        const identity = await context.getIdentity();
        const output = await context
            .getBranchAPI()
            .update(
                IOFactory.createInput(input, identity)
            );
        return output;
    }

    export async function updateQuery(
        context: AppContextValue,
        input: IQuery.UpdateQuery.Input
    ): Promise<APIOutput<Entity<Query.Data>>> {
        const identity = await context.getIdentity();
        const output = await context
            .getQueryAPI()
            .update(
                IOFactory.createInput(input, identity)
            );
        return output;
    }
}