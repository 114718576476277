import { Header, SpaceBetween, Box, RadioGroupProps, Container, FormField, RadioGroup } from "@amzn/awsui-components-react";
import { Branch, QuestionAnswerPair } from "@amzn/lincoln-chatbot-domain";
import React = require("react");
import { QuestionAnswerPairModel } from "../../model/question-answer-pair-model";

export namespace AdditionalInfoComps {
    export const Create = (props: {
        state: QuestionAnswerPairModel.UI.CreateState
        branch: Branch.Data
    }) => {

        const supportedCategories: RadioGroupProps.RadioButtonDefinition[] = props.branch.categories.map((category) => ({
            label: category, value: category
        }))

        // const renderTokenGroup = () => {
        //     return (
        //         <AttributeEditor
        //             definition={OPTIONALTAD_EDITOR_DEFINITION(editorProps)}
        //             items={props.state}
        //             onAddButtonClick={props.addOptionalTag}
        //             onRemoveButtonClick={({ detail: {itemIndex} }) => props.onRemoveOptionTag(itemIndex)}
        //             addButtonText='Add new tag'
        //             removeButtonText='Remove'
        //             disableAddButton={props.displayMode === DisplayMode.View}
        //             isItemRemovable={() => props.displayMode !== DisplayMode.View}
        //             empty='No tags associated with this Q&A pairing'
        //         />
        //     )
        // }

        return (
            <Container
                className='custom-screenshot-hide'
                header={<Header variant='h2'>Tags</Header>}
            >
                <SpaceBetween direction='vertical' size='xl'>
                    <FormField
                        label={
                            <span id='mand-tag-label'>
                                Category tag
                                <Box variant='span' margin={{ left: 'xs' }}>
                                    {/* <InfoLink id='question-creation-info-link' onFollow={() => this.props.updateTools(1)} /> */}
                                </Box>
                            </span>
                        }
                        // errorText={props.submitClicked && props.QAContent.categoryTag.length === 0? "Category is required": null}
                        description='Provide the category for this Q&A pairing. These tags are inherited from this branch of Lincoln.'
                        stretch={false}
                    >
                        <RadioGroup
                            items={supportedCategories}
                            value={props.state.categoryField.value}
                            onChange={({ detail }) => props.state.categoryField.setValue(detail.value)}
                        />
                    </FormField>
                    {/* <FormField
                        label={
                            <span>
                                Other tags
                                <Box variant='span' margin={{left: 'xs'}}>
                                </Box>
                            </span>
                        }
                        description='Add other tags to help Admins search for this Q&A pairing. '
                        stretch={false}
                    >
                    </FormField>
                    {renderTokenGroup()} */}
                </SpaceBetween>
            </Container>
        )
    }

    export const View = (props: {
        qa: QuestionAnswerPair.Data
        branch: Branch.Data
    }) => {
        const supportedCategories: RadioGroupProps.RadioButtonDefinition[] = props.branch.categories.map((category) => ({
            label: category, value: category, disabled: true,
        }))
        return (
            <Container
                className='custom-screenshot-hide'
                header={<Header variant='h2'>Tags</Header>}
            >
                <SpaceBetween direction='vertical' size='xl'>
                    <FormField
                        label={
                            <span id='mand-tag-label'>
                                Category tag
                                <Box variant='span' margin={{ left: 'xs' }}>
                                    {/* <InfoLink id='question-creation-info-link' onFollow={() => this.props.updateTools(1)} /> */}
                                </Box>
                            </span>
                        }
                        // errorText={props.submitClicked && props.QAContent.categoryTag.length === 0? "Category is required": null}
                        description='Provide the category for this Q&A pairing. These tags are inherited from this branch of Lincoln.'
                        stretch={false}
                    >
                        <RadioGroup
                            items={supportedCategories}
                            value={props.qa.category}
                        />
                    </FormField>
                    {/* <FormField
                        label={
                            <span>
                                Other tags
                                <Box variant='span' margin={{left: 'xs'}}>
                                </Box>
                            </span>
                        }
                        description='Add other tags to help Admins search for this Q&A pairing. '
                        stretch={false}
                    >
                    </FormField>
                    {renderTokenGroup()} */}
                </SpaceBetween>
            </Container>
        )
    }
    
    export const Update = (props: {
        state: QuestionAnswerPairModel.UI.UpdateState
        branch: Branch.Data
    }) => {

        const supportedCategories: RadioGroupProps.RadioButtonDefinition[] = props.branch.categories.map((category) => ({
            label: category, value: category
        }))

        // const renderTokenGroup = () => {
        //     return (
        //         <AttributeEditor
        //             definition={OPTIONALTAD_EDITOR_DEFINITION(editorProps)}
        //             items={props.state}
        //             onAddButtonClick={props.addOptionalTag}
        //             onRemoveButtonClick={({ detail: {itemIndex} }) => props.onRemoveOptionTag(itemIndex)}
        //             addButtonText='Add new tag'
        //             removeButtonText='Remove'
        //             disableAddButton={props.displayMode === DisplayMode.View}
        //             isItemRemovable={() => props.displayMode !== DisplayMode.View}
        //             empty='No tags associated with this Q&A pairing'
        //         />
        //     )
        // }

        return (
            <Container
                className='custom-screenshot-hide'
                header={<Header variant='h2'>Tags</Header>}
            >
                <SpaceBetween direction='vertical' size='xl'>
                    <FormField
                        label={
                            <span id='mand-tag-label'>
                                Category tag
                                <Box variant='span' margin={{ left: 'xs' }}>
                                    {/* <InfoLink id='question-creation-info-link' onFollow={() => this.props.updateTools(1)} /> */}
                                </Box>
                            </span>
                        }
                        // errorText={props.submitClicked && props.QAContent.categoryTag.length === 0? "Category is required": null}
                        description='Provide the category for this Q&A pairing. These tags are inherited from this branch of Lincoln.'
                        stretch={false}
                    >
                        <RadioGroup
                            items={supportedCategories}
                            value={props.state.newEntityDataField.value.category}
                            onChange={({ detail }) => props.state.update({ category: detail.value })}
                        />
                    </FormField>
                    {/* <FormField
                        label={
                            <span>
                                Other tags
                                <Box variant='span' margin={{left: 'xs'}}>
                                </Box>
                            </span>
                        }
                        description='Add other tags to help Admins search for this Q&A pairing. '
                        stretch={false}
                    >
                    </FormField>
                    {renderTokenGroup()} */}
                </SpaceBetween>
            </Container>
        )
    }
}