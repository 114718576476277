import { Branch, Entity, EntityFactory, GroupIdentityType, IBranch, Ref } from "@amzn/lincoln-chatbot-domain";
import { Builder } from "builder-pattern";
import { useEffect, useState } from "react";
import { UIModel } from "./general-models";

export namespace BranchModel {
    export namespace UI {
        export class CreateState {
            nameField: UIModel.FieldState<string>;
            displayNameField: UIModel.FieldState<string>;
            domainField: UIModel.FieldState<string>;
            adminTeamGroupIdField: UIModel.FieldState<string>;
            adminTeamGroupTypeField: UIModel.FieldState<GroupIdentityType>;
            displayModeField: UIModel.FieldState<Branch.DisplayMode>;
            static toInput(state: CreateState): IBranch.CreateBranch.Input {
                const origin = location.origin;
                return IBranch.CreateBranch.Input.construct({
                    name: state.nameField.value,
                    displayName: state.displayNameField.value,
                    domain: `${ origin }/#/chatbot/${state.nameField.value}`,
                    adminTeam: {
                        id: state.adminTeamGroupIdField.value,
                        type: state.adminTeamGroupTypeField.value,
                    },
                    displayMode: state.displayModeField.value
                });
            }

            static use(props: { template?: IBranch.CreateBranch.Input }): CreateState {
                const nameField = UIModel.FieldState.use<string>({
                    initialValue: ""
                });
                const displayNameField = UIModel.FieldState.use<string>({
                    initialValue: ""
                });
                const domainField = UIModel.FieldState.use<string>({
                    initialValue: ""
                });
                const adminTeamGroupIdField = UIModel.FieldState.use<string>({});
                const adminTeamGroupTypeField = UIModel.FieldState.use<GroupIdentityType>({
                    initialValue: 2
                });
                const displayModeField = UIModel.FieldState.use<Branch.DisplayMode>({
                    initialValue: "Default"
                });

                useEffect(() => {
                    if (!props.template) return;
                    nameField.setValue(props.template.name);
                    displayNameField.setValue(props.template.displayName);
                    domainField.setValue(props.template.domain);
                    adminTeamGroupIdField.setValue(props.template.adminTeam.id);
                    adminTeamGroupTypeField.setValue(props.template.adminTeam.type);
                }, []);

                return Builder<CreateState>(new CreateState())
                    .nameField(nameField)
                    .displayNameField(displayNameField)
                    .domainField(domainField)
                    .adminTeamGroupIdField(adminTeamGroupIdField)
                    .adminTeamGroupTypeField(adminTeamGroupTypeField)
                    .displayModeField(displayModeField)
                    .build();
            }
        }

        export class UpdateState {
            originalRefField: UIModel.FieldState<Ref>;
            newEntityDataField: UIModel.FieldState<Branch.Data>;
            update: (params: {
                categories?: string[],
                responses?: Branch.Responses
            }) => void;
            reset: () => void;
            init: (entity: Entity<Branch.Data>) => void;
            cache: UIModel.FieldState<Entity<Branch.Data>>;
            
            static toInput(state: UpdateState): IBranch.UpdateBranch.Input {
                return IBranch.UpdateBranch.Input.construct({
                    original: state.originalRefField.value,
                    newData: state.newEntityDataField.value
                });
            }

            static use(props: { originalEntity: Entity<Branch.Data> }): UpdateState {
                const originalRefField = UIModel.FieldState.use<Ref>({
                    initialValue: EntityFactory.toRef(props.originalEntity)
                });
                
                const newEntityDataField = UIModel.FieldState.use<Branch.Data>({
                    initialValue: props.originalEntity.payload
                });

                const [cache, setCache] = useState<Entity<Branch.Data>>(props.originalEntity);

                const init = (entity: Entity<Branch.Data>) => {
                    originalRefField.setValue(EntityFactory.toRef(entity));
                    newEntityDataField.setValue(entity.payload);
                    setCache(entity);
                }

                const reset = () => {
                    originalRefField.setValue(EntityFactory.toRef(cache));
                    newEntityDataField.setValue(cache.payload);
                }

                const update = (params: {
                    categories?: string[],
                    responses?: Branch.Responses
                }) => {
                    const updatedEntityData = Branch.Factory.update(newEntityDataField.value, {
                        responses: params.responses ? params.responses : newEntityDataField.value.responses,
                        categories: params.categories ? params.categories : newEntityDataField.value.categories
                    })
                    newEntityDataField.setValue(updatedEntityData);
                }
                
                return Builder<UpdateState>()
                    .newEntityDataField(newEntityDataField)
                    .originalRefField(originalRefField)
                    .update(update)
                    .reset(reset)
                    .init(init)
                    .build();
            }
        }
    }
}