import { ClientConfig } from "../utils/config";
import { Auth } from "@aws-amplify/auth";
import { RestAPI } from "@aws-amplify/api-rest";

export class AmplifyFactory {
  public static createAuth(config: ClientConfig) {
    // your Cognito Hosted UI configuration
    const oauth = {
      domain: config.cognito.domain,
      scope: config.cognito.scope,
      redirectSignIn: config.cognito.signInRedirectUrl,
      redirectSignOut: config.cognito.signOutRedirectUrl,
      responseType: config.cognito.responseType,
    };
    Auth.configure({
      region: config.awsRegion,
      userPoolId: config.cognito.userPoolName,
      userPoolWebClientId: config.cognito.clientId,
      oauth,
    });
    return Auth;
  }

  public static createRestAPI(config: ClientConfig) {
    const currentConfig = RestAPI.configure({
      endpoints: [
        {
          name: "branch",
          endpoint: config.lincoln.branchApi,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
        {
          name: "kendra",
          endpoint: config.lincoln.kendraApi,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
        {
          name: "query",
          endpoint: config.lincoln.queryApi,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
        {
          name: "question-answer-pair",
          endpoint: config.lincoln.questionAnswerPairApi,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
      ],
    });
    return RestAPI;
  }
}
