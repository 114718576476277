import { APIInput, APIOutput, Entity, IQuery, Query } from "@amzn/lincoln-chatbot-domain";
import { RestAPI } from "@aws-amplify/api-rest";

export interface QueryAPI {
    create(input: APIInput<IQuery.CreateQuery.Input>): Promise<APIOutput<Entity<Query.Data>>>;
    update(input: APIInput<IQuery.UpdateQuery.Input>): Promise<APIOutput<Entity<Query.Data>>>;
}

export class QueryAPIImpl implements QueryAPI{
    async create(
        input: APIInput<IQuery.CreateQuery.Input>
    ): Promise<APIOutput<Entity<Query.Data>>> {
        let myInit = {
            body: input,
            headers: {},
        };
        let response: APIOutput<Entity<Query.Data>> = await RestAPI.post(
            "query",
            "/query/create",
            myInit
        );
        return response;
    }

    async update(
        input: APIInput<IQuery.UpdateQuery.Input>
    ): Promise<APIOutput<Entity<Query.Data>>> {
        let myInit = {
            body: input,
            headers: {},
        };
        let response: APIOutput<Entity<Query.Data>> = await RestAPI.post(
            "query",
            "/query/update",
            myInit
        );
        return response;
    }

}