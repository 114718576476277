import { AttributeEditor, Button, ColumnLayout, FormField, Tabs, Textarea, SpaceBetween, Header, TextContent, Container } from "@amzn/awsui-components-react";
import { Entity, Branch, QuestionAnswerPair } from "@amzn/lincoln-chatbot-domain";
import React = require("react");
import { BranchModel } from "../../model/branch-model";
import { APIModel, UIModel } from "../../model/general-models";
import { CustomHooks } from "../../utils/async-runner";
import { CATEGORY_EDITOR_DEFINITION } from "./constants";
import OverviewPanel from "./overview-panel";
import { SearchPanel } from "./searching-panel";
import ReactMarkdown from "react-markdown";

type TabId = "qapairings" | "category" | "Responses";

export const BranchDetailContent = (props: {
    branch: Entity<Branch.Data>;
    qaPairs: Entity<QuestionAnswerPair.Data>[];
}) => {
    const [branchLocal, setBranchLocal] = React.useState<Entity<Branch.Data>>(props.branch);
    let updateBranchInputState = BranchModel.UI.UpdateState.use({
        originalEntity: props.branch
    })

    const qaPairState = UIModel.FieldState.use<Entity<QuestionAnswerPair.Data>[]>({
        initialValue: props.qaPairs
    })

    const activeTabId = UIModel.FieldState.use<TabId>({
        initialValue: "qapairings"
    })

    const checkCategoryDependency = () => {
        alert("Sorry, function not implemented yet");
    }

    const deleteQAPair = () => {
        alert("Sorry, function not implemented yet");
    }

    const onBranchUpdated = (updated: Entity<Branch.Data>) => {
        setBranchLocal(updated);
        updateBranchInputState.init(updated);
    }

    return (
        <SpaceBetween direction='vertical' size='l'>
            <OverviewPanel branch={branchLocal.payload}/>
            <Tabs
                activeTabId={activeTabId.value}
                onChange={(event) => activeTabId.setValue(event.detail.activeTabId as TabId)}
                tabs={[
                {
                    label: "Q&A pairings",
                    id: 'qapairings',
                    content: (
                        <SearchPanel
                            state={qaPairState}
                            branch={branchLocal}
                        />
                    )
                },
                {
                    label: "Category tags",
                    id: 'category',
                    content: (
                        <CategoryTab 
                            branch={branchLocal.payload}
                            state={updateBranchInputState}
                            onUpdated={onBranchUpdated}
                        />
                    )
                },
                {
                    label: "Responses",
                    id: 'responses',
                    content: (
                        <ResponsesTab
                            branch={branchLocal.payload}
                            state={updateBranchInputState}
                            onUpdated={onBranchUpdated}
                        />
                    )
                }
            ]}
            />
        </SpaceBetween>
    )
}

const ResponsesTab = (props: {
    branch: Branch.Data;
    state: BranchModel.UI.UpdateState;
    onUpdated: (updated: Entity<Branch.Data>) => void;
}) => {
    const [editingGreetings, setEditingGreetings] = React.useState<boolean>(false);
    const [editingResponses, setEditingResponses] = React.useState<boolean>(false);
    const [editingFollowups, setEditingFollowups] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const updateBranchRunner = CustomHooks.useRunner(
        APIModel.updateBranch
    );

    const update = () => {
        setLoading(true);
        updateBranchRunner.submitRun(
            BranchModel.UI.UpdateState.toInput(props.state)
        );
    };

    React.useEffect(() => {
        if (updateBranchRunner.status === "OK") {
            setLoading(false);
            setEditingFollowups(false);
            setEditingResponses(false);
            setEditingGreetings(false);
            props.onUpdated(updateBranchRunner.runState.data);
        }
    }, [updateBranchRunner.status]);

    return (
        <React.Fragment>
            <Container
                header={
                    <Header 
                        variant='h2' 
                        description='Lincoln will show these greeting messages'
                        actions={
                            editingGreetings?
                            (
                                <SpaceBetween direction='horizontal' size='m'>
                                    <Button loading={loading} onClick={() => {
                                        props.state.reset();
                                        setEditingGreetings(false);
                                    }} variant='link'>Cancel</Button>
                                    <Button loading={loading} variant='primary' onClick={update}>Save</Button>

                                </SpaceBetween>
                            ):
                            (<Button loading={loading} onClick={() => setEditingGreetings(true)}>Edit</Button>)
                        }>
                        Greetings
                    </Header>
                }>
                <ColumnLayout columns={2} variant='text-grid'>
                    <SpaceBetween direction='vertical' size='l'>
                        <FormField
                            description='When user first time open up Lincoln'
                            stretch={true}>
                            { editingGreetings?
                            (
                                <Textarea
                                    onChange={({ detail }) =>
                                        props.state.update({
                                            responses: Branch.Factory.updateResponse(
                                                props.state.newEntityDataField.value.responses,
                                                {
                                                    greetings: { openUpGreeting_1: detail.value }
                                                }
                                            )
                                        })
                                    }
                                    value={props.state.newEntityDataField.value.responses.greetings.openUpGreeting_1}/>
                            ): 
                            (   
                                <ReactMarkdown>
                                    {props.branch.responses.greetings.openUpGreeting_1}
                                </ReactMarkdown>
                            )}
                        </FormField>
                    </SpaceBetween>
                    <SpaceBetween direction='vertical' size='l'>
                        <FormField
                            description='When user first time open up Lincoln'
                            stretch={true}>
                            { editingGreetings?
                            (
                                <Textarea
                                    onChange={({ detail }) =>
                                        props.state.update({
                                            responses: Branch.Factory.updateResponse(
                                                props.state.newEntityDataField.value.responses,
                                                {
                                                    greetings: { openUpGreeting_2: detail.value }
                                                }
                                            )
                                        })
                                    }
                                    value={props.state.newEntityDataField.value.responses.greetings.openUpGreeting_2}/>
                            ): 
                            (   
                                <ReactMarkdown>
                                    {props.branch.responses.greetings.openUpGreeting_2}
                                </ReactMarkdown>
                            )}
                        </FormField>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
            <Container
                header={
                    <Header 
                        variant='h2' 
                        description='Lincoln will provide these responses based search result'
                        actions={
                            editingResponses?
                            (
                                <SpaceBetween direction='horizontal' size='m'>
                                    <Button loading={loading} onClick={() => {
                                        setEditingResponses(false);
                                        props.state.reset();
                                    }} variant='link'>Cancel</Button>
                                    <Button loading={loading} variant='primary' onClick={update}>Save</Button>
                                </SpaceBetween>
                            ):
                            (<Button loading={loading} onClick={() => setEditingResponses(true)}>Edit</Button>)
                        }>
                        Responses
                    </Header>
                }>
                <ColumnLayout columns={2} variant='text-grid'>
                    <ColumnLayout columns={1} borders='horizontal'>
                        <SpaceBetween direction='vertical' size='l'>
                            <TextContent><h5>Multiple options response</h5></TextContent>
                            <FormField
                                description='When Lincoln gets multiple potential questions'
                                stretch={true}>
                                { editingResponses?
                                (
                                    <Textarea
                                        onChange={({ detail }) =>
                                            props.state.update({
                                                responses: Branch.Factory.updateResponse(
                                                    props.state.newEntityDataField.value.responses,
                                                    {
                                                        inProgressResponses: { multipleAnswersFoundResponse: detail.value }
                                                    }
                                                )
                                            })
                                        }
                                        value={props.state.newEntityDataField.value.responses.inProgressResponses.multipleAnswersFoundResponse}/>
                                ): 
                                (
                                    <ReactMarkdown>
                                        {props.branch.responses.inProgressResponses.multipleAnswersFoundResponse}
                                    </ReactMarkdown>
                                )}
                            </FormField>
                        </SpaceBetween>
                    </ColumnLayout>
                    <SpaceBetween direction='vertical' size='l'>
                        <TextContent><h5>No match responses</h5></TextContent>
                        <FormField
                            label={ <p><small><strong>First</strong></small></p> }
                            description='When Lincoln cannot find answer'
                            stretch={true}>
                            { editingResponses?
                            (
                                <Textarea
                                    onChange={({ detail }) =>
                                        props.state.update({
                                            responses: Branch.Factory.updateResponse(
                                                props.state.newEntityDataField.value.responses,
                                                {
                                                    inProgressResponses: { firstAnswerNotFoundResponse: detail.value }
                                                }
                                            )
                                        })
                                    }
                                    value={props.state.newEntityDataField.value.responses.inProgressResponses.firstAnswerNotFoundResponse}/>
                            ):
                            (
                                <ReactMarkdown>
                                    {props.branch.responses.inProgressResponses.firstAnswerNotFoundResponse}
                                </ReactMarkdown>
                            )}
                        </FormField>
                        <FormField
                            label={ <p><small><strong>Second</strong></small></p> }
                            description="When user ask second time and Lincoln still couldn't find an answer "
                            stretch={true}>
                            { editingResponses?
                            (  
                                <Textarea
                                    onChange={({ detail }) =>
                                        props.state.update({
                                            responses: Branch.Factory.updateResponse(
                                                props.state.newEntityDataField.value.responses,
                                                {
                                                    inProgressResponses: { secondAnswerNotFoundResponse: detail.value }
                                                }
                                            )
                                        })
                                    }
                                    value={props.state.newEntityDataField.value.responses.inProgressResponses.secondAnswerNotFoundResponse}/>
                            ):
                            (
                                <ReactMarkdown>
                                    {props.branch.responses.inProgressResponses.secondAnswerNotFoundResponse}
                                </ReactMarkdown>
                            )
                            }
                        </FormField>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
            <Container
                header={
                    <Header 
                        variant='h2' 
                        description='Lincoln will provide these responses based on user feedback (thumb up/thumb down)'
                        actions={
                            editingFollowups?
                            (
                                <SpaceBetween direction='horizontal' size='m'>
                                    <Button loading={loading} onClick={() => {
                                        setEditingFollowups(false);
                                        props.state.reset();
                                    }} variant='link'>Cancel</Button>
                                    <Button loading={loading} variant='primary' onClick={update}>Save</Button>
                                </SpaceBetween>
                            ):
                            (<Button loading={loading} onClick={() => setEditingFollowups(true)}>Edit</Button>)
                        }>
                        Follow-ups
                    </Header>
                }>
                <ColumnLayout columns={2} variant='text-grid'>
                    <SpaceBetween direction='vertical' size='l'>
                        <TextContent><h5>Positive feedback</h5></TextContent>
                        <FormField
                            label={ <p><small><strong>First</strong></small></p> }
                            description='When a user gives an answer a thumbs up'
                            stretch={true}
                        >
                            { editingFollowups?
                            (
                                <Textarea
                                    onChange={({ detail }) =>
                                        props.state.update({
                                            responses: Branch.Factory.updateResponse(
                                                props.state.newEntityDataField.value.responses,
                                                {
                                                    feedbackFollowUps: { positiveFeedback: detail.value }
                                                }
                                            )
                                        })
                                    }
                                    value={props.state.newEntityDataField.value.responses.feedbackFollowUps.positiveFeedback}
                                />
                            ): 
                            (   
                                <ReactMarkdown>
                                    {props.branch.responses.feedbackFollowUps.positiveFeedback}
                                </ReactMarkdown>
                            )}
                        </FormField>
                    </SpaceBetween>
                    <SpaceBetween direction='vertical' size='l'>
                        <TextContent><h5>Negative feedback</h5></TextContent>
                        <FormField
                            label={ <p><small><strong>First</strong></small></p> }
                            description='When a user gives an answer a thumbs down'
                            stretch={true}
                        >
                            { editingFollowups?
                            (
                                <Textarea
                                    onChange={({ detail }) =>
                                        props.state.update({
                                            responses: Branch.Factory.updateResponse(
                                                props.state.newEntityDataField.value.responses,
                                                {
                                                    feedbackFollowUps: { firstNegativeFeedback: detail.value }
                                                }
                                            )
                                        })
                                    }
                                    value={props.state.newEntityDataField.value.responses.feedbackFollowUps.firstNegativeFeedback}
                                />
                            ):
                            (
                                <ReactMarkdown>
                                    {props.branch.responses.feedbackFollowUps.firstNegativeFeedback}
                                </ReactMarkdown>
                            )}
                        </FormField>
                        <FormField
                            label={ <p><small><strong>Second</strong></small></p> }
                            description='When a user has given the second answer a thumbs down after asking the question again'
                            stretch={true}
                        >
                            { editingFollowups?
                            (  
                                <Textarea
                                    onChange={({ detail }) =>
                                    props.state.update({
                                        responses: Branch.Factory.updateResponse(
                                            props.state.newEntityDataField.value.responses,
                                            {
                                                feedbackFollowUps: { secondNegativeFeedback: detail.value }
                                            }
                                        )
                                    })
                                    }
                                    value={props.state.newEntityDataField.value.responses.feedbackFollowUps.secondNegativeFeedback}
                                />
                            ):
                            (
                                <ReactMarkdown>
                                    {props.branch.responses.feedbackFollowUps.secondNegativeFeedback}
                                </ReactMarkdown>
                            )
                            }
                        </FormField>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
        </React.Fragment>
    )
}

const CategoryTab = (props: {
    branch: Branch.Data;
    state: BranchModel.UI.UpdateState;
    onUpdated: (updated: Entity<Branch.Data>) => void;
}) => {
    const [editing, setEditing] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const removeCategoryFromIndex = (index: number) => {
        const categories = props.state.newEntityDataField.value.categories.slice();
        categories.splice(index, 1);
        props.state.update({ categories: categories })
    }

    const updateBranchRunner = CustomHooks.useRunner(
        APIModel.updateBranch
    );

    const update = () => {
        setLoading(true);
        updateBranchRunner.submitRun(
            BranchModel.UI.UpdateState.toInput(props.state)
        );
    }

    React.useEffect(() => {
        if (updateBranchRunner.status === "OK") {
            props.onUpdated(updateBranchRunner.runState.data);
            setLoading(false);
            setEditing(false);
        }
    }, [updateBranchRunner.status]);

    const addCategory = () => {
        const categories = props.state.newEntityDataField.value.categories.slice();
        categories.push("");
        props.state.update({ categories: categories })
    }

    const updateCategory = (value: string, index: number) => {
        const categories = props.state.newEntityDataField.value.categories.slice();
        categories[index] = value;
        props.state.update({ categories: categories })
    }

    return (
        <div>
            <Container
                header={
                    <Header 
                        variant='h2' 
                        description='Q&A pairings are categorized using a Category tag'
                        actions={ 
                            editing? (
                            <SpaceBetween direction='horizontal' size='m'>
                                <Button loading={loading} variant='link' onClick={() => setEditing(false)}>Cancel</Button>
                                <Button loading={loading} onClick={props.state.reset}>Reset</Button>
                                <Button loading={loading} onClick={update}>Save</Button>
                            </SpaceBetween> ) : (
                                <Button variant='primary' onClick={() => setEditing(true)}>Edit</Button>
                            )
                        }
                    >
                        Category tags
                    </Header>
                }
            >
                <AttributeEditor 
                    definition={CATEGORY_EDITOR_DEFINITION({onChange: updateCategory, readOnly: !editing})}
                    items={props.state.newEntityDataField.value.categories}
                    onRemoveButtonClick={({ detail: { itemIndex }}) => {
                        removeCategoryFromIndex(itemIndex)
                    }}
                    onAddButtonClick={addCategory}
                    addButtonText='Add new Category'
                    removeButtonText='Remove'
                    disableAddButton={!editing}
                    isItemRemovable={() => editing}
                />
            </Container>
            {/* <Modal
                visible={this.state.displayWarningModal}
                onDismiss={this.closeWarningModal.bind(this)}
                header='Warning'
                footer={<Box float='right'><Button variant='link' onClick={this.closeWarningModal.bind(this)}>Okay</Button></Box>}
            >
                <p>Category tag <strong>{ this.state.warningModalIndex >= 0? this.props.updateBranchInputState.value.newData.payload.categories[this.state.warningModalIndex]: 'Unknown' }</strong> could not be removed</p>
                <p>Change any Q&A pairings with this category tag to a different category tag and try again.</p>
            </Modal> */}
        </div>
    )
}