import { APIOutput, ErrorOutput, PrimitiveDataFactory } from "@amzn/lincoln-chatbot-domain";
import { Builder } from "builder-pattern";
import React = require("react");
import { AppContext } from "./context";
import { AppContextValue } from "./context-value";

export class RunState<P, T> {
    id: string = PrimitiveDataFactory.id();
    props?: P;
    data?: T;
    err?: ErrorOutput;

    static create<P, T>(props: P) {
        let builder = Builder<RunState<P, T>>(new RunState<P, T>()).props(props);
        return builder.build();
    }

    static finish<T>(
        state: RunState<any, T>,
        id: string,
        err?: ErrorOutput,
        data?: T
    ) {
        if (state.id !== id) return state;

        let builder = Builder<RunState<any, T>>(state).err(err).data(data);
        return builder.build();
    }
}

type RunnerStatus = "New" | "Running" | "OK" | "Error";

export class RunnerState<P, T> {
    runState?: RunState<P, T>;
    history: RunState<P, T>[] = [];
    status: RunnerStatus = "New";
    submitRun: (p: P) => void;
    retry: () => void;

    static create<P, T>() {
        return Builder<RunnerState<P, T>>(new RunnerState<P, T>()).build();
    }

    static finishRun<P, T>(state: RunnerState<P, T>, run: RunState<P, T>) {
        if (state.status !== "Running") return;

        if (run.id !== state.runState.id) {
            console.log(
            `Dropping result for run ${run.id} becaue latest run is ${state.runState.id}`
            );
            return;
        }
        let builder = Builder<RunnerState<any, T>>(state)
            .runState(run)
            .status(run.err ? "Error" : "OK")
            .history([...state.history, run]);
        return builder.build();
    }

    static submitRun<P>(state: RunnerState<P, any>, props: P) {
        const newRunState = RunState.create<P, any>(props);
        let builder = Builder<RunnerState<P, any>>(state)
            .runState(newRunState)
            .status("Running");
        return builder.build();
    }

    static retry<P>(state: RunnerState<P, any>) {
        if (!state.runState) return state;
        const newRunState = RunState.create<P, any>(state.runState.props);
        let builder = Builder<RunnerState<P, any>>(state)
            .runState(newRunState)
            .status("Running");
        return builder.build();
    }
}

export namespace CustomHooks {
    function run<P, T>(
        func: (context: AppContextValue, p: P) => Promise<APIOutput<T>>,
        runnerState: RunnerState<P, T>,
        setRunnerState: (runnerState: RunnerState<P, T>) => void,
        context: AppContextValue
      ) {
        if (runnerState.status !== "Running") return;
        const run = runnerState.runState;
        func(context, run.props)
            .then((result) => {
                if (!!result.err) {
                    console.debug(
                        `runId=${run.id} props=${JSON.stringify(
                        run.props
                        )}, err=${JSON.stringify(result.err)}`
                    );
                    const newState = RunnerState.finishRun(
                        runnerState,
                        RunState.finish(run, run.id, result.err, null)
                    );
                    setRunnerState(newState);
                } else {
                    console.debug(
                        `runId=${run.id} props=${JSON.stringify(
                        run.props
                        )}, value=${JSON.stringify(result.data)}`
                    );
                    const newState = RunnerState.finishRun(
                        runnerState,
                        RunState.finish(run, run.id, null, result.data)
                    );
                    setRunnerState(newState);
                }
            })
            .catch((e) => {
                console.error(e);
                console.debug(
                    `runId=${run.id} props=${JSON.stringify(run.props)}, err=${e}`
                );
                const newState = RunnerState.finishRun(
                    runnerState,
                    RunState.finish(
                        run,
                        run.id,
                        run.err,
                        null
                    )
                );
                setRunnerState(newState);
            });
    }


    export function useRunner<P, T>(
        func: (context: AppContextValue, p: P) => Promise<APIOutput<T>>,
        p?: P
      ): RunnerState<P, T> {
        const context = React.useContext(AppContext);
        const [runnerState, setRunnerState] = React.useState<
            RunnerState<P, T>
        >(new RunnerState());
    
        React.useEffect(() => {
            if (runnerState.status === "Running")
                run(func, runnerState, setRunnerState, context);
        }, [runnerState.status]);
    
        React.useEffect(() => {
            if (!p) return;
            setRunnerState(RunnerState.submitRun(runnerState, p));
        }, [p]);
    
        const submitRun = (p: P) => {
            setRunnerState(RunnerState.submitRun(runnerState, p));
        };
    
        const retry = () => {
            setRunnerState(RunnerState.retry(runnerState));
        };
    
        return { ...runnerState, retry, submitRun };
    }
}