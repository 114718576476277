import React = require("react");
import styled from "@emotion/styled";
import { SpaceBetween } from "@amzn/awsui-components-react";
import '../../style/header.scss'
import '../../style/floating-banner.css';
import { UIModel } from "../../model/general-models";
import { ChatbotOpenStatus } from "./common";
import { Branch } from "@amzn/lincoln-chatbot-domain";
const logo = require('../../assets/logo.png').default

interface HeaderProps {
    chatbotOpenState: UIModel.FieldState<ChatbotOpenStatus>;
    branchId: string;
}

interface HeaderState {
    activatedClass: string;
}

export class Header extends React.Component<HeaderProps, HeaderState> {

    constructor(props) {
        super(props)
        this.state = {
            activatedClass: '',
        }
    }

    onExpanded() {
        this.setState({activatedClass: this.props.chatbotOpenState.value === ChatbotOpenStatus.EXPANDED ? 'expandanimation': ''})
    }

    defaultHeader() {
        if (this.props.chatbotOpenState.value === ChatbotOpenStatus.FULLSIZE) {
            return (
                <div className="fullsize-default-header-text">
                    <p style={{color: "#FF9900"}} unselectable='on'>AWS</p>
                    <p style={{color: "#FFFFFF"}} unselectable='on'>Legal</p>
                </div>
            )
        } else {
            return (
                <SpaceBetween direction='horizontal' size='m'>
                    <p style={{color: "#FF9900"}} unselectable='on'>AWS</p>
                    <p className={`${this.props.chatbotOpenState.value === ChatbotOpenStatus.EXPANDED? 'header-lincoln-char-expand': ''}`} unselectable='on'>Legal</p>
                </SpaceBetween>
            )
        }
    }

    customizedHeader() {
        if (this.props.branchId === "bce") {
            if (this.props.chatbotOpenState.value === ChatbotOpenStatus.FULLSIZE) {
                return <div>
                    <p>{"business conduct & ethics"}</p>
                </div>
            } else {
                return (
                    <div>
                        <p className={`${this.props.chatbotOpenState.value === ChatbotOpenStatus.EXPANDED? 'header-bce-expand': 'header-bce-fold'}`}>{"business conduct & ethics"}</p>
                    </div>
                )
            }
        } else if (this.props.branchId === "apj-marketing") {
            return <div className="fullsize-default-header-text">
                <p style={{color: "#FF9900"}}>APJ MARKETING</p>
                <p style={{color: "#FFFFFF"}}>DIGITAL PORTAL</p>
            </div>;
        } else if (this.props.branchId === "WWPS" ) {
            return <div className="fullsize-default-header-text">
                <p style={{color: "#FF9900"}}>AWS</p>
                <p style={{color: "#FFFFFF"}}>Worldwide Public Sector</p>
            </div>;
        } else {
            return this.defaultHeader();
        }
    }

    render() {
        if (this.props.chatbotOpenState.value === ChatbotOpenStatus.FULLSIZE) {
            return (
                <div>
                    <div className="fullsize-header">
                        {this.customizedHeader()}
                        <div className='fullsize-header-badge'>
                            Beta
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={`header ${ this.props.chatbotOpenState.value === ChatbotOpenStatus.EXPANDED? 'header-expand': '' }`}>
                    <div className={`aws-ui ask-lincoln ${this.props.chatbotOpenState.value === ChatbotOpenStatus.EXPANDED? 'ask-lincoln-expand': ''}`}>
                        {this.customizedHeader()}
                    </div>
                    {this.props.chatbotOpenState.value === ChatbotOpenStatus.EXPANDED? (
                        <div className='header-badge'>
                            Beta
                        </div>
                    ): (
                        <LogoCircleDiv>
                            <i><LogoImg src={String(logo)} draggable={false}/></i>
                        </LogoCircleDiv>
                    )}
                </div>
            )
        }
    }
}

export const LogoImg = styled.img`
    height: 39px;
    draggable: false;
    position: relative;
    right: 19px;
    bottom: 18px;
`

export const LogoCircleDiv = styled.div`
    position: fixed;
    bottom: 199px;
    right: 231px;
    width: 1px;
    height: 1px;  
    border-radius: 50%;
    background: white;
    color: white;
    padding: 28px;
    border: 2px solid #4D636C;
    z-index: 1;
`
