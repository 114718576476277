import { Select, Textarea, Button, ColumnLayout, Form, FormField, Input, Container } from "@amzn/awsui-components-react";
import { Branch, GroupIdentityType } from "@amzn/lincoln-chatbot-domain";
import React = require("react");
import { useHistory } from "react-router-dom";
import { BranchModel } from "../../model/branch-model";
import { APIModel } from "../../model/general-models";
import { CustomHooks } from "../../utils/async-runner";

export const BranchCreate = () => {
    const history = useHistory();
    const createInputState = BranchModel.UI.CreateState.use({});
    const createBranchRunner = CustomHooks.useRunner(
        APIModel.createBranch
    );

    const create = () => {
        createBranchRunner.submitRun(
            BranchModel.UI.CreateState.toInput(createInputState)
        );
    };

    React.useEffect(() => {
        if (createBranchRunner.status === "OK") {
            history.push(`/admin`)
        }
    }, [createBranchRunner.status]);

    const toGroupIdentityType = (type: string) => {
        if (type === "LdapGroup") {
            return GroupIdentityType.LdapGroup;
        } else if (type === "PosixGroup") {
            return GroupIdentityType.PosixGroup
        } else {
            return GroupIdentityType.Team
        }
    }

    return (
        <React.Fragment>
            <Form
                header={
                    <span>
                        <h1 className="awsui-util-d-ib">Create Branch</h1>
                    </span>
                }
                actions={
                    <div>
                        <Button onClick={() => history.goBack()} variant="link">
                        Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={(e) => create()}
                            loading={createBranchRunner.status === "Running"}>
                            Submit
                        </Button>
                    </div>
                }>
                <Container header={<h2>Branch details</h2>}>
                    <ColumnLayout columns={2}>
                        <div data-awsui-column-layout-root={true}>
                        <FormField
                            label={<span>Name</span>}>
                            <Input
                                ariaRequired={true}
                                placeholder="Name"
                                value={createInputState.nameField.value}
                                onChange={(e) =>
                                    createInputState.nameField.setValue(e.detail.value)
                                }/>
                        </FormField>
                        <FormField
                            label={<span>Display Name</span>}>
                            <Textarea
                                ariaRequired={true}
                                placeholder="Display Name"
                                value={createInputState.displayNameField.value}
                                onInput={(e) =>
                                    createInputState.displayNameField.setValue(e.detail.value)
                                }
                            />
                        </FormField>
                        <FormField
                            label={<span>Admin team id</span>}>
                            <Input
                                ariaRequired={true}
                                placeholder="Admin team id"
                                value={createInputState.adminTeamGroupIdField.value}
                                onChange={(e) =>
                                    createInputState.adminTeamGroupIdField.setValue(e.detail.value)
                                }
                            />
                        </FormField>
                        <FormField
                            label={<span>Display Mode</span>}>
                            <Select
                                options={[
                                    {
                                        label: "Fullsize",
                                        id: "fullsize"
                                    },
                                    {
                                        label: "Default",
                                        id: "default"
                                    }
                                ]}
                                selectedId={createInputState.displayModeField.value}
                                onChange={(e) => {
                                    createInputState.displayModeField.setValue(
                                        e.detail.selectedId as Branch.DisplayMode
                                    )
                                }}
                            />
                        </FormField>
                        </div>
                    </ColumnLayout>
                </Container>
            </Form>
        </React.Fragment>
    )
}