import React = require("react");
import { Form, Header, Spinner } from "@amzn/awsui-components-react";
import { CustomHooks } from "../../utils/async-runner";
import { APIModel } from "../../model/general-models";
import { Ref } from "@amzn/lincoln-chatbot-domain";
import { BranchDetailContent } from "../../components/branch/branch-detail";

export interface ChatbotConfig {
    positiveFeedback: string;
    firstNegativeFeedback: string;
    secondNegativeFeedback: string;
    introMessage: string[];
    multipleOptionsMessage: string;
    firstAnswerNotFoundMessage: string;
    retryAnswerNotFoundMessage: string;
}

export const ManageHubBranchPage = (props: {
    branchRef: Ref
}) => {
    const loadBranchRunner = CustomHooks.useRunner(
        APIModel.loadBranch
    );

    const loadAllQAPairsRunner = CustomHooks.useRunner(
        APIModel.loadQuestionAnswerPairsByBranch
    );

    const BasePageContent = ({ content, branchDisplayName}) => {
        return (
            <div>
                <Form
                    header ={
                        <Header
                            variant='h1'
                        >
                            { branchDisplayName }
                        </Header>
                    }
                >
                    {content}
                </Form>
            </div>
        )
    }

    React.useEffect(() => {
        loadAllQAPairsRunner.submitRun(props.branchRef);
        loadBranchRunner.submitRun(props.branchRef);
    }, [props.branchRef.id]);

    return (
        <BasePageContent
            branchDisplayName={loadBranchRunner.status === "OK" ? loadBranchRunner.runState.data.payload.displayName : props.branchRef.id}
            content={
                <div>
                    {(loadAllQAPairsRunner.status === "Running" || loadBranchRunner.status === "Running") &&
                        <span className="awsui-util-status-inactive">
                            <Spinner /> Loading
                        </span>
                    }
                    {(loadAllQAPairsRunner.status === "Error" || loadBranchRunner.status === "Error") &&
                        <span className="awsui-util-status-inactive">
                            Error Loading
                        </span>
                    }
                    {(loadAllQAPairsRunner.status === "OK" && loadBranchRunner.status === "OK") &&
                        <BranchDetailContent
                            branch={loadBranchRunner.runState.data}
                            qaPairs={loadAllQAPairsRunner.runState.data}
                        />
                    }
                </div>
            }
        />
    )
}