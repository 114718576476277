import { Icon } from '@amzn/awsui-components-react';
import styled from '@emotion/styled';
import React = require('react')
import { UIModel } from '../../model/general-models';
import '../../style/chatcircle.css'
import { ChatbotOpenStatus } from './common';

const chatCircleIcon = require('../../assets/chat-circle-icon.png').default

interface ChatCircleProps {
    chatbotOpenState: UIModel.FieldState<ChatbotOpenStatus>;
    onExpandChatWindow: () => void;
    onCloseAllWindow: () => void;
}

interface ChatCircleState {
    closeWindowClicked: boolean
    expandWindowClicked: boolean
    displayLogo: boolean
}

export default class ChatCircle extends React.Component<ChatCircleProps, ChatCircleState> {

    constructor(props) {
        super(props)
        this.state = {
            closeWindowClicked: false,
            expandWindowClicked: false,
            displayLogo: true
        }
    }

    onClick() {
        if (this.props.chatbotOpenState.value === ChatbotOpenStatus.OPEN) {
            this.setState({ closeWindowClicked: true })
            this.props.onCloseAllWindow()
        } else if (this.props.chatbotOpenState.value === ChatbotOpenStatus.EXPANDED) {
            this.setState({ closeWindowClicked: true })
            this.props.onCloseAllWindow()
        }
        else {
            this.setState({ expandWindowClicked: true })
            this.props.onExpandChatWindow()
        }
    }

    onExpandWindow() {
        this.setState({ expandWindowClicked: true })
        this.props.onExpandChatWindow()
    }

    onAnimationEnd() {
        this.setState({
            closeWindowClicked: false,
            expandWindowClicked: false,
            displayLogo: this.props.chatbotOpenState.value !== ChatbotOpenStatus.OPEN
        })
    }

    logoButtonClassName() {
        if (this.state.expandWindowClicked) {
            return 'circle-logo-fade-out'
        }
        else if (this.state.closeWindowClicked) {
            return 'circle-logo-fade-in'
        }
        else {
            return ''
        }
    }

    crossButtonClassName() {
        if (this.state.expandWindowClicked) {
            return 'circle-cross-fade-in'
        }
        else if (this.state.closeWindowClicked) {
            return 'circle-cross-fade-out'
        }
        else {
            return ''
        }
    }

    render () {
        return (
            <div 
                className={`chatcircle `} 
                onClick={this.onClick.bind(this)}
                onAnimationEnd={this.onAnimationEnd.bind(this)}
            >
                <AwsIconDiv style={{visibility: this.state.displayLogo? 'hidden': 'visible', paddingTop: '10px'}} className={`aws-ui ${this.crossButtonClassName()}`}>
                    <Icon name='close' size='big' variant='inverted'/>
                </AwsIconDiv>
                <LogoDiv style={{visibility: this.state.displayLogo? 'visible': 'hidden'}} className={`${this.logoButtonClassName()}`}>
                    <img src={String(chatCircleIcon)} draggable={false}/>
                </LogoDiv>
            </div> 
        )
    }
}

const AwsIconDiv = styled.div`
    position: fixed;
    bottom: 37px;
    right: 49px;
`


const LogoDiv = styled.div`
    position: fixed;
    bottom: 39px;
    right: 45px;
    & > img {
        height: 27px;
    }
`
