import { Entity, QuestionAnswerPair } from "@amzn/lincoln-chatbot-domain"

export namespace PolarisFactory {
    export namespace PropertyFilter {
        export const TextProperty = {
            key: "text",
            operators: ["=", ":"],
            propertyLabel: "Question or Answer text",
        }
        export const Category = {
            key: "category",
            operators: ["=", ":"],
            propertyLabel: "Category",
            groupValuesLabel: "Category values"
        }

        export function toOptions(qnas: Entity<QuestionAnswerPair.Data>[]) {
            const options = [];
            for (const qna of qnas) {
                if (options.find(o => o.propertyKey === Category.key && o.value === qna.payload.category)) {
                    continue;
                }
                options.push({
                    propertyKey: Category.key,
                    value: qna.payload.category
                })
            }
            return options;
        }
    }
}