import { Builder } from "builder-pattern";
import { Ref } from "../entity";

export namespace QuestionAnswerPair {
    export interface Data {
        question: string;
        answer: string;
        branch: Ref;
        category: string;
        tags: string[];
    }

    export class Factory {
        static create(params: {
            question: string;
            answer: string;
            branch: Ref;
            category: string;
            tags: string[];
        }): Data {
            return Builder<Data>()
                .question(params.question)
                .answer(params.answer)
                .branch(params.branch)
                .category(params.category)
                .tags(params.tags)
                .build();
        }

        static update(original: Data, newData: {
            question?: string;
            answer?: string;
            branch?: Ref;
            category?: string;
            tags?: string[];
        }): Data {
            const builder = Builder<Data>(original);
            if (newData.question) {
                builder.question(newData.question);
            }
            if (newData.answer) {
                builder.answer(newData.answer);
            }
            if (newData.branch) {
                builder.branch(newData.branch);
            }
            if (newData.category) {
                builder.category(newData.category)
            }
            if (newData.tags) {
                builder.tags(newData.tags);
            }
            return builder.build();
        }
    }
}