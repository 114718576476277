import * as React from "react";
import {
    Spinner,
    AppLayout
} from "@amzn/awsui-components-react";
import {
    HashRouter,
    Route,
} from "react-router-dom";
import { ClientConfig  } from "../utils/config";
import { AppContext } from "../utils/context";
import { Builder } from "builder-pattern";
import { Hub } from "@aws-amplify/core";
import { Navigation } from "../components/Commons/Naviagtion";
import { routes } from "./page-routes";
import { AuthPage } from "./webpages/AuthPageForIFrame";

export enum Status {
    Authenticated,
    UnAuthenticated,
    Error,
}

class AppState {
    status: Status;
    config: ClientConfig;
    navigationExpanded: boolean;

    constructor() {
        this.status = Status.UnAuthenticated;
        this.config = null
        this.navigationExpanded = false;
    }

    public static fail(state: AppState): AppState {
        return Builder(state).status(Status.Error).build()
    }

    public static authenticated(state: AppState, config: ClientConfig): AppState {
        return Builder(state).status(Status.Authenticated).config(config).build()
    }

    public static unAuthenticated(state: AppState): AppState {
        return Builder(state).status(Status.UnAuthenticated).build()
    }

    public static toggleNavigation(state: AppState, expanded: boolean) {
        return Builder(state).navigationExpanded(expanded).build()
    }
    
}

export const App = () => {
    const context = React.useContext(AppContext)

    React.useEffect(() => {
        context.init()
    })

    const content = () => {
        const contents =  routes.map((r) => {
            return (
                <Route
                    exact
                    path={ r.path }
                    render={ (matchprops) => {
                        if (r.path.includes('iframe')) { //iframe mode
                            return <r.content {...matchprops}/>;
                        } else if (r.path === '/auth') {
                            return <AuthPage/>
                        }
                        else {
                            return <AppWithAuth/>
                        }
                    }}
                />
            )
        })
        return contents
    }

    return (
        <AppContext.Provider value={context}>
            <HashRouter>
                { content() }
            </HashRouter>
        </AppContext.Provider>
    )
}

export const AppWithAuth = () => {
    const context = React.useContext(AppContext);
    
    const [appState, setAppState] = React.useState<AppState>(new AppState());

    const onSignedIn = async () => {
        await context.getIdentity();
        setAppState(
            AppState.authenticated(appState, await context.getConfig())
        );
        if (localStorage.getItem("OrignalURLHashBeforeAuth")) {
            window.location.href = localStorage.getItem("OrignalURLHashBeforeAuth");
            localStorage.removeItem("OrignalURLHashBeforeAuth");
        }
    }

    const signInFailed = async (message: string) => {
        console.error(message);
        setAppState(
            AppState.fail(appState)
        );
    };
    
    const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));

    const content = () => {
        const contents =  routes.map((r) => {
            return (
                <Route
                    exact
                    path={r.path}
                    render={(matchProps) => {
                        if (r.path.includes("chatbot")) {
                            return <r.content {...matchProps}/>;
                        }
                        if (r.path === '/auth') {
                            return <r.content {...matchProps}/>;
                        } else {
                            return (
                                <div className='awsui'>
                                    <AppLayout
                                        contentType={r.contentType}
                                        navigation={<Navigation/>}
                                        breadcrumbs={r.breadcrumbItems? <r.breadcrumbItems/>: null}
                                        content={<r.content {...matchProps}/>}
                                        maxContentWidth={r.maxContentWidth? r.maxContentWidth: null}
                                        navigationOpen={appState.navigationExpanded}
                                        onNavigationChange={(event) => onNavigationToggle(event.detail.open)}
                                    />
                                </div>
                            )
                        }
                    }}
                />
            )
        })
        return contents
    }

    const init = async () => {
        try {
            Hub.listen("auth", (data) => {
                switch (data.payload.event) {
                case "signIn":
                    console.log("receive signIn event");
                    onSignedIn();
                    break;
                case "signIn_failure":
                    console.log(
                    `receive signIn failed event with message ${data.payload.message}`
                    );
                    signInFailed(data.payload.message);
                    break;
                default:
                    break;
                }
            });
            await context.init();
            /* Why sleeps 500ms here?
            * This is because we want to wait 500ms to let browser wait the return tokens from Auth,
            * otherwise the browser will re-trigger another sign-in process (because the browser
            * has not get the tokens yet, so browser thinks the user has not yet signed in),
            * and the re-triggered sign-in process will cause unexpected error in Firefox and Safari
            */
            await sleep(500);
            await context.currentUser();
            await onSignedIn();
        } catch (err) {
            console.warn(err);
            if (!window.location.href.includes("code") && !window.location.href.includes("state")) {
                localStorage.setItem("OrignalURLHashBeforeAuth", window.location.href);
            }
            await context.signIn();
        }
    };

    const onNavigationToggle = (expanded: boolean) => {
        setAppState(
            AppState.toggleNavigation(appState, expanded)
        );
    };

    React.useEffect(() => {
        init();
    }, []);

    return (
        <div>
            {appState.status === Status.UnAuthenticated && (
                <span className="awsui-util-status-inactive">
                    <Spinner /> Loading
                </span>
            )}
            {appState.status === Status.Error && (
                <div>
                    Failed to load. Please try to refresh the page. If the issue still
                    persists after multiple tries. Please contact altar-dev@amazon.com for
                    support.
                </div>
            )}
            {appState.status === Status.Authenticated && (
                <React.Fragment>
                    <AppContext.Provider value={context}>
                        <HashRouter>
                            {content()}
                        </HashRouter>
                    </AppContext.Provider>
                </React.Fragment>
            )}
        </div>
    );
}
