import { Spinner } from "@amzn/awsui-components-react";
import { EntityType, Ref } from "@amzn/lincoln-chatbot-domain";
import { Builder } from "builder-pattern";
import React = require("react");
import { QuestionAnswerPairCreateContent } from "../../components/question-answer-pair/create";
import { QuestionAnswerPairDetailContent } from "../../components/question-answer-pair/detail";
import { APIModel } from "../../model/general-models";
import { CustomHooks } from "../../utils/async-runner";

export const QuestionAnswerPairDetail = (props: {
    branchId: string;
    qaId: string
}) => {
    const loadBranchRunner = CustomHooks.useRunner(
        APIModel.loadBranch
    );

    const loadQAPairsRunner = CustomHooks.useRunner(
        APIModel.loadQuestionAnswerPair
    );

    React.useEffect(() => {
        loadBranchRunner.submitRun(
            Builder<Ref>()
                .id(props.branchId)
                .type(EntityType.Branch)
                .build()
        );
    }, [props.branchId]);

    React.useEffect(() => {
        if (!props.qaId) return;
        loadQAPairsRunner.submitRun(
            Builder<Ref>()
                .id(props.qaId)
                .type(EntityType.QuestionAnswerPair)
                .build()
        );
    }, [props.qaId]);

    return (
        <React.Fragment>
            {(loadBranchRunner.status === "Running" || loadQAPairsRunner.status === "Running") &&
                <span className="awsui-util-status-inactive">
                    <Spinner /> Loading
                </span>
            }
            {(loadBranchRunner.status === "Error" || loadQAPairsRunner.status === "Error") &&
                <span className="awsui-util-status-inactive">
                    <Spinner /> Loading Error
                </span>
            }
            {loadBranchRunner.status === "OK" && loadQAPairsRunner.status === "OK" &&
                <QuestionAnswerPairDetailContent
                    entityBranch={loadBranchRunner.runState.data}
                    entity={loadQAPairsRunner.runState.data}
                />
            }
        </React.Fragment>
    )
}