import { Builder } from "builder-pattern";

const ACTOR_TYPE_PRINCIPAL = "principal";

export namespace BrassService {
    export interface IGateway {
        isMemberOf(input: IsMemberOf.Input): Promise<IsMemberOf.Output>
    }

    export namespace IsMemberOf {
        export class Actor {
            actorType: string;
            actorId: string;
          
            static createPrincipalActor(principal: string): Actor {
                return Builder<Actor>()
                    .actorId(principal)
                    .actorType(ACTOR_TYPE_PRINCIPAL)
                    .build();
            }
        }

        export class Input {
            actor: Actor;
            group: {
                groupType: string;
                groupId: string;
            }

            static create(params: {
                actor: Actor;
                groupType: string;
                groupId: string;
            }): Input {
                return Builder<Input>(new Input())
                    .actor(params.actor)
                    .group({
                        groupType: params.groupType,
                        groupId: params.groupId
                    })
                    .build();
            }
        }

        export class Output {
            member: boolean;

            static construct(value: boolean): Output {
                return Builder<Output>(new Output())
                    .member(value)
                    .build();
            }
        }
    }
}