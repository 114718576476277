import { Spinner } from "@amzn/awsui-components-react";
import { EntityType, Ref } from "@amzn/lincoln-chatbot-domain";
import { Builder } from "builder-pattern";
import React = require("react");
import { QuestionAnswerPairCreateContent } from "../../components/question-answer-pair/create";
import { APIModel } from "../../model/general-models";
import { CustomHooks } from "../../utils/async-runner";

export const QuestionAnswerPairCreate = (props: {
    branchId: string
}) => {
    const loadBranchRunner = CustomHooks.useRunner(
        APIModel.loadBranch
    );

    React.useEffect(() => {
        loadBranchRunner.submitRun(
            Builder<Ref>()
                .id(props.branchId)
                .type(EntityType.Branch)
                .build()
        );
    }, [props.branchId]);

    return (
        <React.Fragment>
            {(loadBranchRunner.status === "Running") &&
                <span className="awsui-util-status-inactive">
                    <Spinner /> Loading
                </span>
            }
            {(loadBranchRunner.status === "Error") &&
                <span className="awsui-util-status-inactive">
                    <Spinner /> Loading Error
                </span>
            }
            {loadBranchRunner.status === "OK" && <QuestionAnswerPairCreateContent branch={loadBranchRunner.runState.data}/>}
        </React.Fragment>
    )
}