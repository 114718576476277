import { ClientConfig, ClientConfigFactory } from "./config";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { AmplifyFactory } from "../factory/amplify-factory";
import { Builder } from "builder-pattern";
import { AppController } from "./common";
import { BranchAPI, BranchAPIImpl } from "../api/branch.api";
import { QuestionAnswerPairAPI, QuestionAnswerPairAPIImpl } from "../api/question-answer-pair.api";
import { Identity } from "@amzn/lincoln-chatbot-domain";
import { KendraSearchAPI, KendraSearchAPIImpl } from "../api/kendra-search.api";
import { QueryAPI, QueryAPIImpl } from "../api/query.api";

export interface AppContextValue {
    init(): Promise<void>;
    signIn(): Promise<void>;
    signOut(): Promise<void>;
    currentUser(): Promise<CognitoUser>;
    getIdentity(): Promise<Identity>;
    getConfig(): Promise<ClientConfig>;
    getBranchAPI(): BranchAPI;
    getKendraSearchAPI(): KendraSearchAPI;
    getQuestionAnswerPairAPI(): QuestionAnswerPairAPI;
    getQueryAPI(): QueryAPI;
    getAppControllers(): Set<AppController>;
}

export class AppContextValueImpl implements AppContextValue {
    
    config: ClientConfig;
    appController: Set<AppController>;
    branchAPI: BranchAPI;
    questionAnswerPairAPI: QuestionAnswerPairAPI;
    kendraSearchAPI: KendraSearchAPI;
    queryAPI: QueryAPI;
    
    async init(): Promise<void> {
        this.config = await this.getConfig();
        AmplifyFactory.createAuth(this.config);
        AmplifyFactory.createRestAPI(this.config);
        this.branchAPI = new BranchAPIImpl();
        this.questionAnswerPairAPI = new QuestionAnswerPairAPIImpl();
        this.kendraSearchAPI = new KendraSearchAPIImpl();
        this.queryAPI = new QueryAPIImpl();

        this.appController = new Set<AppController>();

        this.appController.add(AppController.AudioDisabled)
    }

    async signIn(): Promise<void> {
        console.log(`signIn()`);
        await Auth.federatedSignIn({
          customProvider: "AmazonFederateOIDC",
        });
    }

    async signOut(): Promise<void> {
        await Auth.signOut();
        await this.signIn();
    }

    async currentUser(): Promise<CognitoUser> {
        let user: CognitoUser = await Auth.currentAuthenticatedUser();
        console.log(
            `current user is ${JSON.stringify(
                user.getSignInUserSession().getIdToken().decodePayload()["identities"][0]["userId"]
            )}`
        );
        return user;
    }

    async getIdentity(): Promise<Identity> {
        const token = (await this.currentUser())
            .getSignInUserSession()
            .getIdToken()
            .decodePayload();
        let cognitoIdentity = Builder<Identity>()
            .type(0)
            .id(token["identities"][0]["userId"].split("@")[0])
            .name(`${token["given_name"]} ${token["family_name"]}`)
            .email(token["email"])
            .build();
        return cognitoIdentity;
    }

    async getConfig(): Promise<ClientConfig> {
        return await ClientConfigFactory.getConfig();
    }

    getAppControllers(): Set<AppController> {
        return this.appController
    }
    
    getBranchAPI(): BranchAPI {
        return this.branchAPI;
    }

    getQuestionAnswerPairAPI(): QuestionAnswerPairAPI {
        return this.questionAnswerPairAPI;
    }

    getKendraSearchAPI(): KendraSearchAPI {
        return this.kendraSearchAPI;
    }

    getQueryAPI(): QueryAPI {
        return this.queryAPI;
    }
}