import { Builder } from "builder-pattern";
import { KendraService } from "../../interface/service/kendra-service.gateway";
import { Ref } from "../entity";

export namespace Query {
    export type Feedback = "Thumb-up" | "Thumb-down" | "No-feedback";
    export interface Data {
        history: History[];
        branch?: string;
        feedback: Feedback;
        totalAttempts: number;
    }
    export interface History {
        userInput: string;
        result: KendraService.KendraSearch.Result[];
        retry: number;
    }

    export class Factory {
        static createQueryData(params: {
            history: History[];
            feedback: Feedback;
            totalAttempts: number;
            branch?: string;
        }): Data {
            return Builder<Data>()
                .history(params.history)
                .feedback(params.feedback)
                .totalAttempts(params.totalAttempts)
                .branch(params.branch)
                .build();
        }

        static createHistoryData(params: {
            userInput: string;
            result: KendraService.KendraSearch.Result[];
            retry: number;
        }): History {
            return Builder<History>()
                .userInput(params.userInput)
                .result(params.result)
                .retry(params.retry)
                .build();
        }

        static updateQuery(data: Data, params: {
            newHistory?: History;
            feedback?: Feedback;
            totalAttempts?: number
        }): Data {
            const builder = Builder<Data>(data);
            if (params.newHistory) {
                const histories = data.history.slice();
                histories.push(params.newHistory);
                builder.history(histories);
            }
            if (params.feedback) {
                builder.feedback(params.feedback);
            }
            if (params.totalAttempts) {
                builder.totalAttempts(params.totalAttempts);
            }
            return builder.build();
        }
    }
}