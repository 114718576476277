import { Builder } from "builder-pattern";
import { APIInput, APIOutput, Entity, Ref, EntityType } from "..";

export namespace IEntity {
    export interface API {
        create<T>(
          input: APIInput<Create.Input<T>>
        ): Promise<APIOutput<Entity<T>>>;
        update<T>(
          input: APIInput<Update.Input<T>>
        ): Promise<APIOutput<Entity<T>>>;
        updateLabelAndTags<T>(
          input: APIInput<UpdateLabelTags.Input<T>>
        ): Promise<APIOutput<Entity<T>>>;
        load<T>(input: APIInput<Ref>): Promise<APIOutput<Entity<T>>>;
        batchLoad<T>(
          input: APIInput<Ref[]>
        ): Promise<APIOutput<BatchLoad.Output<T>>>;
        loadAll<T>(input: APIInput<EntityType>): Promise<APIOutput<Entity<T>[]>>;
        loadByIndex<T>(input: APIInput<{entityType: EntityType, indexValue: string}>): Promise<APIOutput<Entity<T>[]>>;
        delete<T>(input: APIInput<Ref>): Promise<APIOutput<Entity<T>>>;
    }

    export namespace Create {
        export class Input<T> {
            type: EntityType;
            data: T;
            name?: string;
            description?: string;
            id?: string; // Use this id instead of generated id
            gsiIndex?: string // Secondary index for this entity

            static construct<T>(
                type: EntityType,
                data: T,
                optional: {
                    name?: string;
                    description?: string;
                    id?: string;
                    gsiIndex?: string;
                }
            ) {
            return Builder<Input<T>>(new Input<T>())
                .data(data)
                .type(type)
                .name(optional.name)
                .description(optional.description)
                .id(optional.id)
                .gsiIndex(optional.gsiIndex)
                .build();
            }
        }
    }

    export namespace Update {
        export class Input<T> {
            ref: Ref;
            newData: T;
            name?: string;
            description?: string;

            static construct<T>(ref: Ref, newData: T, optional: {
                name?: string;
                description?: string;
            }): Input<T> {
                return Builder<Input<T>>(new Input<T>())
                    .ref(ref)
                    .newData(newData)
                    .name(optional.name)
                    .description(optional.description)
                    .build();
            }
        }
    }

    export namespace UpdateLabelTags {
        export class Input<T> {}
    }
    
    export namespace BatchLoad {
        export class Output<T> {
            loaded: Entity<T>[] = [];

            static construct<T>(loaded: Entity<T>[]) {
                return Builder<Output<T>>(new Output<T>())
                    .loaded(loaded)
                    .build();
            }
        }
    }
}