import React = require("react");
import styled from '@emotion/styled'
import PulseLoader from 'react-spinners/PulseLoader'
import '../../style/body.scss'
import { Query } from "@amzn/lincoln-chatbot-domain";
import { UIModel } from "../../model/general-models";
import { Conversation } from "../../utils/conversation-model";
import { ChatbotOpenStatus, MsgSender } from "./common";
import ReactMarkdown from "react-markdown";
const thumbupblack = require('../../assets/thumb-up-black.png').default
const thumbdownblack = require('../../assets/thumb-down-black.png').default
const thumbupwhite = require('../../assets/thumb-up-white.png').default
const thumbdownwhite = require('../../assets/thumb-down-white.png').default
const logo = require('../../assets/logo.png').default

interface DisplayPanelProps {
    chatbotOpenState: UIModel.FieldState<ChatbotOpenStatus>
    isLoading: boolean
    isProcessingAnswer: boolean
    conversationState: UIModel.FieldState<Conversation[]>;
    updateQueryFeedback(feedback: Query.Feedback): void
}

export class DisplayPanel extends React.Component<DisplayPanelProps, {}> {
    private messagesEnd

    constructor(props) {
        super(props)
    }

    selectQuestion(event) {
        const selectedQuestion = event.target.textContent
        const answer = event.target.dataset.answer
        const isVoice = event.target.dataset.isvoice
        const voice = event.target.dataset.voice
        
        const selectConv = new Conversation(MsgSender.User)
        selectConv.setContent(selectedQuestion)

        const answerConv = new Conversation(MsgSender.Lincoln)
        answerConv.setContent(answer)
        if (isVoice === 'true') {
            this.playVoiceMessage(voice)
        }
        answerConv.displayFeedback()
        answerConv.disableLogo()
        this.props.conversationState.setValue([
            ...this.props.conversationState.value,
            selectConv,
            answerConv,
        ])
    }

    sendFeedback(event) {
        const inquiryId = event.target.dataset.inquiryid
        const feedback: Query.Feedback = event.target.dataset.feedback
        console.log(`Feedback for inquriyId: ${ inquiryId }, feedback is: ${ feedback }`)
        this.props.updateQueryFeedback(feedback)
    }

    scrollToBottom() {
        this.messagesEnd.scrollIntoView({ behavior: 'smooth' })
    }

    componentDidMount(){
        this.scrollToBottom()
    }

    componentDidUpdate() {
        this.scrollToBottom()
    }

    showFeedback(conv: Conversation) {
        if (conv.shouldDisplayFeedback()) {
            return (
                <this.ChatbotMsgDiv>
                    <this.ChatbotMsgText>
                        Was that helpful?
                    </this.ChatbotMsgText>
                    { !conv.feedbackSubmitted() && (
                        <div>
                        <this.FeedbackDiv>
                            <img 
                                src={String(thumbupblack)} 
                                height={this.isFullsize() ? "50px" : "25px"}
                                onClick={(event) => {
                                    console.log("Feedback submitted")
                                    conv.onSubmitFeedback()
                                    this.sendFeedback(event)
                                }} 
                                data-feedback={"Thumb-up"} 
                                style={{cursor: 'pointer'}}
                                draggable={false}
                            />
                        </this.FeedbackDiv>
                        <this.FeedbackDiv>
                            <img 
                                src={String(thumbdownblack)} 
                                height={this.isFullsize() ? "50px" : "25px"}
                                onClick={(event) => {
                                    console.log("Feedback submitted")
                                    conv.onSubmitFeedback()
                                    this.sendFeedback(event)
                                }}
                                data-feedback={"Thumb-down"} 
                                style={{cursor: 'pointer'}}
                                draggable={false}
                            />
                        </this.FeedbackDiv>
                        </div>
                    )}
                    <div style={{clear: "both"}}><this.LogoDiv>
                        { <img src={String(logo)} height={this.isFullsize() ? "50px" : "25px"} draggable={false}/> }
                    </this.LogoDiv></div>
                </this.ChatbotMsgDiv>
            )
        }
    }

    showUserMsg(conv: Conversation) {
        if(conv.getContent() === "Thumb-up") {
            return (
                <this.UserMsgDiv>
                    <this.UserMsgText>
                        <img src={String(thumbupwhite)} height={this.isFullsize() ? "50px" : "25px"} draggable={false}/>
                    </this.UserMsgText>
                </this.UserMsgDiv>
            )
        }
        else if (conv.getContent() === "Thumb-down") {
            return (
                <this.UserMsgDiv>
                    <this.UserMsgText>
                        <img src={String(thumbdownwhite)} height={this.isFullsize() ? "50px" : "25px"} draggable={false}/>
                    </this.UserMsgText>
                </this.UserMsgDiv>
            )
        }
        else {
            return (
                <this.UserMsgDiv>
                    <this.UserMsgText>
                        {conv.getContent()}
                    </this.UserMsgText>
                </this.UserMsgDiv>
            )
        }
    }

    showChatbotMsg(conv: Conversation) {

        const ParagraphBreak = styled.br``
        return (
            <div>
                <this.ChatbotMsgDiv>
                    <this.ChatbotMsgText>
                        <ReactMarkdown>
                            {conv.getContent()}
                        </ReactMarkdown>
                    </this.ChatbotMsgText>
                    {
                        conv.shouldDisplayLogo && 
                        <div style={{clear: "both"}}><this.LogoDiv>
                            { conv.shouldDisplayLogo() && <img src={String(logo)} height={this.isFullsize() ? "50px" : "25px"} draggable={false}/> }
                        </this.LogoDiv></div>
                    }
                </this.ChatbotMsgDiv>
                {this.showFeedback(conv)}
            </div>
        )
    }

    showChatbotMsgClickable(conv: Conversation) {
        const clickableStyle = {
            cursor: 'pointer',
            textDecoration: 'underline',
            color: '#0026CF'
        }
        return (
            <this.ChatbotMsgDiv>
                <this.ChatbotMsgText 
                    onClick={this.selectQuestion.bind(this)} 
                    data-answer={conv.getPendingAnswer()} 
                    data-inquiryid={conv.getInquiryId()}
                    data-isvoice={conv.playable()}
                    data-voice={conv.getVoiceAnswer()}
                    style={clickableStyle}
                >
                    {conv.getContent()}
                </this.ChatbotMsgText>
                {
                    conv.shouldDisplayLogo && 
                    <div style={{clear: "both"}}><this.LogoDiv>
                        { conv.shouldDisplayLogo() && <img src={String(logo)} height={this.isFullsize() ? "50px" : "25px"} draggable={false}/> }
                    </this.LogoDiv></div>
                }
            </this.ChatbotMsgDiv>
        )
    }

    playVoiceMessage(voiceString: string) {
        const buffer = Buffer.from(voiceString, 'base64')
        const voiceAnswerBlobURL = window.URL.createObjectURL(new Blob([buffer], {type: 'audio/mpeg'}))
        const answerPlayback = new Audio(voiceAnswerBlobURL)
        answerPlayback.play()
    }

    showMessage(conv: Conversation) {
        if (!conv.isSendbyUser()) {
            if (conv.isClickable()) {
                return this.showChatbotMsgClickable(conv)
            }
            else {
                return this.showChatbotMsg(conv)
            }
        }
        else {
            return this.showUserMsg(conv)
        }
    }

    isFullsize() {
        return this.props.chatbotOpenState.value === ChatbotOpenStatus.FULLSIZE;
    }

    render() {
        if (this.isFullsize()) {
            return (
                <div className="fullsize-displaypanel">
                    { !!this.props.conversationState.value && this.props.conversationState.value.map((conv) => this.showMessage(conv)) }
                    { (this.props.isLoading || this.props.isProcessingAnswer) && 
                        <this.ChatbotMsgDiv>
                            <this.ChatbotMsgText>
                                <PulseLoader size='4px' margin='5px' color='#F3950D'/>
                            </this.ChatbotMsgText>
                        </this.ChatbotMsgDiv>
                    }
                    <div style={{ float:"left", clear: "both" }}
                        ref={(el) => { this.messagesEnd = el; }}>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={`display-div ${ this.props.chatbotOpenState.value === ChatbotOpenStatus.EXPANDED ? 'fade-in': '' }`}>
                    { !!this.props.conversationState.value && this.props.conversationState.value.map((conv) => this.showMessage(conv)) }
                    { (this.props.isLoading || this.props.isProcessingAnswer) && 
                        <this.ChatbotMsgDiv>
                            <this.ChatbotMsgText>
                                <PulseLoader size='4px' margin='5px' color='#F3950D'/>
                            </this.ChatbotMsgText>
                        </this.ChatbotMsgDiv>
                    }
                    <div style={{ float:"left", clear: "both" }}
                        ref={(el) => { this.messagesEnd = el; }}>
                    </div>
                </div>
            );
        }
    }
    ChatbotMsgText = styled.p`
        left: 19px !important;
        margin-block-start: 0px !important;
        margin-block-end: 0px !important;
        font-size: ${this.isFullsize() ? "20px" : "13px"} !important;
        font-weight: 400 !important;
        color: #16191f !important;
        float: left !important;
        background: #eaeded !important;
        padding:10px 15px 10px 15px !important;
        margin-left: ${this.isFullsize() ? "40px" : "10px"} !important; 
        margin-top: ${this.isFullsize() ? "10px" : "5px"} !important;
        position:relative !important;
        margin-bottom: 5px !important;
        border-radius: 25px 25px 25px 5px !important;
        max-width: 70% !important;
        font-family: "Amazon Ember", Helvetica, Arial, sans-serif !important;
        p:first-child {
            margin-block-start: 0px !important;
        };
        p:last-child {
            margin-block-end: 0px !important;
        };
    `

    UserMsgText = styled.p`
        margin-block-start: 0px !important;
        margin-block-end: 0px !important;
        font-size: ${this.isFullsize() ? "20px" : "13px"} !important;
        font-weight: 400 !important;
        float: right !important;
        background: #0073bb !important;
        padding:10px 15px 10px 15px !important;
        margin-left:10px !important; 
        position:relative !important;
        margin-top: 5px !important;
        margin-bottom:5px !important;
        border-radius:25px 25px 5px 25px !important;
        max-width: 70% !important;
        color: #ffffff !important;
        font-family: "Amazon Ember", Helvetica, Arial, sans-serif !important;
    `

    LogoDiv = styled.div`
        float: left !important;
        position: absolute !important;
        bottom: 4px !important;
        ${this.isFullsize() ?
            "left: 0px !important;" :
            "right: 231px !important;"
        }
    `

    UserMsgDiv = styled.div`
        float: right !important;
        position:relative !important;
        width: ${this.isFullsize() ? "100%" : "250px"} !important;
    `

    FeedbackDiv = styled.div`
        font-size: 18px !important;
        float: left !important;
        position:relative !important;
        margin-right: 10px !important;
        max-width: 70% !important;
        left: 23px !important;
        top: 8px !important;
    `

    ChatbotMsgDiv = styled.div`
        float: left !important;
        position:relative !important;
        width: ${this.isFullsize() ? "100%" : "250px"} !important;
        ${this.isFullsize() ? "margin-bottom: 15px" : ""}
    `
}



