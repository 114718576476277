import React = require('react')
import { ChatbotBody } from './chatbot-body'
import { Header } from './Header'
import '../../style/chatbot-main.css'
import { Branch, Entity } from '@amzn/lincoln-chatbot-domain'
import { ChatbotOpenStatus } from './common'
import { UIModel } from '../../model/general-models'

interface ChatbotBodyProps {
    branch: Entity<Branch.Data>
    chatbotOpenState: UIModel.FieldState<ChatbotOpenStatus>
}

export class ChatbotCoreComp extends React.Component<ChatbotBodyProps, {}> {

    constructor(props) {
        super(props)
        this.state = {}
    }

    onExpand() {
        if (this.props.chatbotOpenState.value === ChatbotOpenStatus.FULLSIZE) return;
        if (this.props.chatbotOpenState.value !== ChatbotOpenStatus.EXPANDED) {
            this.props.chatbotOpenState.setValue(ChatbotOpenStatus.EXPANDED);
        }
    }

    render() {
        if (this.props.chatbotOpenState.value === ChatbotOpenStatus.FULLSIZE) {
            return (
                <div className="fullsize-core">
                    <div>
                        <Header
                            chatbotOpenState={this.props.chatbotOpenState}
                            branchId={this.props.branch.id}
                        />
                        <ChatbotBody
                            chatbotOpenState={this.props.chatbotOpenState}
                            onExpand={this.onExpand.bind(this)}
                            branch={this.props.branch}
                        />
                    </div>
                </div>
            )
        } else {
            return (
                <div className={this.props.chatbotOpenState.value !== ChatbotOpenStatus.CLOSED ? '': 'fade-out'}>
                    <div
                        style={{visibility: this.props.chatbotOpenState.value !== ChatbotOpenStatus.CLOSED ? 'visible': 'hidden'}}
                        className={`chatbot-main ${ this.props.chatbotOpenState.value !== ChatbotOpenStatus.CLOSED ? 'main-fade-in': ''} ${ this.props.chatbotOpenState.value === ChatbotOpenStatus.EXPANDED? 'main-expand': ''}`}>
                        <Header
                            chatbotOpenState={this.props.chatbotOpenState}
                            branchId={this.props.branch.id}
                        />
                        <ChatbotBody
                            chatbotOpenState={this.props.chatbotOpenState} 
                            onExpand={this.onExpand.bind(this)}
                            branch={this.props.branch}
                        />
                    </div>
                </div>
            )
        }
    }
}