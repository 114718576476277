import React = require("react");
import { AppContext } from "../../utils/context";

export const AuthPage = () => {
    const context = React.useContext(AppContext)

    const onSignedIn = () => {
        clearDestination()
        localStorage.setItem("AuthPageClosed", "true")
        window.close();
    }

    const storeDestination = () => {
        const curUrl = window.location.href
        localStorage.setItem('destinationUrl', curUrl);
    }

    const clearDestination = () => {
        localStorage.removeItem("destinationUrl")
    }

    const sleep = (time: number) => new Promise(res => setTimeout(res, time))

    React.useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try{
            await context.init();
            await sleep(500);
            await context.currentUser();
            await onSignedIn()
        } catch(e){
            storeDestination()
            await context.signIn()
        }
    }

    return (
        <div>
            This page is displayed in order to get you authenticated through midway, if midway login does not pop up, please try refreshing the page
        </div>
    )
}



