import { SpaceBetween } from "@amzn/awsui-components-react";
import { Branch, Entity, EntityFactory } from "@amzn/lincoln-chatbot-domain";
import React = require("react");
import { useHistory } from "react-router-dom";
import { APIModel, UIModel } from "../../model/general-models";
import { QuestionAnswerPairModel } from "../../model/question-answer-pair-model";
import { CustomHooks } from "../../utils/async-runner";
import { AdditionalInfoComps } from "./additional-info-comps";
import { AnswerComps } from "./answer-comps";
import { DisplayMode, QADetailBaseFormContent } from "./common";
import { QuestionComps } from "./question-comps";

export const QuestionAnswerPairCreateContent = (props: {
    branch: Entity<Branch.Data>
}) => {
    const history = useHistory();
    const [cancelModalState, setCancelModalState] = React.useState<boolean>(false);
    const [dupCheckButtonState, setDupCheckButtonState] = React.useState<boolean>(false);
    const createQAInputState = QuestionAnswerPairModel.UI.CreateState.use({});

    React.useEffect(() => {
        createQAInputState.branchRefField.setValue(EntityFactory.toRef(props.branch));
    }, []);

    const createQARunner = CustomHooks.useRunner(
        APIModel.createQuestionAnswerPair
    );
    

    const createQAPair = () => {
        createQARunner.submitRun(
            QuestionAnswerPairModel.UI.CreateState.toInput(createQAInputState)
        );
    };

    React.useEffect(() => {
        if (createQARunner.status === "OK") {
            history.push(
                `/admin/${ props.branch.id }`
            );
        }
    }, [createQARunner.status])

    const onCancelClick = () => {
        setCancelModalState(true);
    }

    return (
        <QADetailBaseFormContent
            displayMode={DisplayMode.Create}
            onSubmit={createQAPair}
            onReset={() => createQAInputState.reset()}
            onCancelClick={onCancelClick}
            mainButtonStatus={createQARunner.status === "Running"}
            content={
                <SpaceBetween size="l">
                    <QuestionComps.Create state={createQAInputState}/>
                    <AnswerComps.Create state={createQAInputState}/>
                    <AdditionalInfoComps.Create state={createQAInputState} branch={props.branch.payload}/>
                </SpaceBetween>
            }
        />
    )
}