export interface Identity {
    type: IdentityType;
    id: string;
    name: string;
    groupIdentities?: GroupIdentity[];
    email: string;
}

export declare enum IdentityType {
    Person = 0,
    System = 1,
}

export declare enum GroupIdentityType {
    LdapGroup = 0,
    PosixGroup = 1,
    Team = 2,
}

export interface GroupIdentity {
    type: GroupIdentityType;
    id: string,
}