import { Container, Header, SpaceBetween, TextContent, Link } from "@amzn/awsui-components-react";
import { Branch } from "@amzn/lincoln-chatbot-domain";
import React = require("react");

interface OverviewPanelProps {
    branch: Branch.Data;
}

export default function OverviewPanel (props: OverviewPanelProps) {
    return (
        <Container header={<Header variant='h2'>Overview</Header>}>
            <SpaceBetween direction='vertical' size='l'>
                <TextContent>
                    Chatbot url
                </TextContent>
                <Link
                    external
                    externalIconAriaLabel='Opens in a new tab'
                    href={props.branch.domain}
                >
                    {props.branch.domain}
                </Link>
            </SpaceBetween>
        </Container>
    )
}