import { SpaceBetween } from "@amzn/awsui-components-react"
import React = require("react")
import { LogoCircleDiv, LogoImg } from "../../components/chatbot/Header";
const logo = require('../../assets/logo.png').default
import "../../style/chatbot-main.css";
import "../../style/header.scss";

export const ChatbotLoadingPage = (props: {error: boolean}) => {

    const waitingMessage = () => {
        return (
            <p className="chatbot-loading-message">
                Oops, looks like you are not authenticated throught Midway,
                Make sure you are allowing pop-ups for this site.
                Alternatively, click <a href="/#/auth" target="_blank">here</a> to get yourself authenticated.
            </p>
        )
    }

    const errorMessage = () => {
        return (
            <p className="chatbot-loading-message">
                Oops, looks like we failed to authenticate you,
                Please refresh the page, if you still see this message, 
                Please contact AWS ALTAR
            </p>
        )
    }
    return (
        <div className="chatbot-main">
            <div className="header">
                <div className='aws-ui ask-lincoln'>
                    <SpaceBetween direction='horizontal' size='m'>
                        <p style={{color: "#FF9900"}} unselectable='on'>AWS</p>
                        <p unselectable='on'>Legal</p>
                    </SpaceBetween>
                </div>
                <LogoCircleDiv>
                    <i><LogoImg src={String(logo)} draggable={false}/></i>
                </LogoCircleDiv>
            </div>
            <div className="chatbot-body">
                { props.error? errorMessage() : waitingMessage() }
            </div>
        </div>
    )
}