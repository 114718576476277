import { Box, Button, Cards, Header, Modal, PropertyFilter, PropertyFilterProps, SpaceBetween, TextContent } from "@amzn/awsui-components-react";
import { Entity, Branch, QuestionAnswerPair, EntityFactory } from "@amzn/lincoln-chatbot-domain";
import * as React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PolarisFactory } from "../../factory/polaris-factory";
import { APIModel, UIModel } from "../../model/general-models";
import { CustomHooks } from "../../utils/async-runner";
import { getCounterString, TableHeader } from "../Commons/TableHeader";
import { CARD_DEFINITION } from "./constants";

export const SearchPanel = (props: {
    branch: Entity<Branch.Data>;
    state: UIModel.FieldState<Entity<QuestionAnswerPair.Data>[]>;
}) => {
    const [ selectedItems, setSelectedItems ] = React.useState<
        Entity<QuestionAnswerPair.Data>[]
    >([]);
    const [ deleteModalVisible, setDeleteModalVisible ] = React.useState<boolean>(false);
    const history = useHistory();
    const [query, setQuery] = React.useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: "and"
    });
    const [filteredItems, setFilteredItems] = React.useState<
        Entity<QuestionAnswerPair.Data>[]
    >(props.state.value);

    const deleteQAPairRunner = CustomHooks.useRunner(
        APIModel.deleteQAPair
    )

    const applyFilter = () => {
        if (query.tokens.length === 0) {
            setFilteredItems(props.state.value)
        } else {
            let filtered = props.state.value.slice();
            if (query.operation === "and") {
                for (const token of query.tokens) {
                    if (token.operator === "=" || token.operator === ":") {
                        if (token.propertyKey === PolarisFactory.PropertyFilter.Category.key) {
                            filtered = filtered.filter(v => v.payload.category === token.value);
                        } else if (token.propertyKey === PolarisFactory.PropertyFilter.TextProperty.key) {
                            filtered = filtered.filter(v =>
                                v.payload.answer.toLowerCase().includes(token.value.toLowerCase()) ||
                                v.payload.question.toLowerCase().includes(token.value.toLowerCase())    
                            )
                        } else if (!token.propertyKey) {
                            filtered = filtered.filter(v =>
                                v.payload.answer.toLowerCase().includes(token.value.toLowerCase()) ||
                                v.payload.question.toLowerCase().includes(token.value.toLowerCase()) ||
                                v.payload.category === token.value  
                            )
                        }
                    }
                }
            }
            setFilteredItems(filtered);
        }
    }

    useEffect(() => {
        applyFilter();
    }, [query.tokens, query.operation]);

    const deleteQnA = async () => {
        deleteQAPairRunner.submitRun(
            EntityFactory.toRef(selectedItems[0])
        );
    }

    useEffect(() => {
        if (deleteQAPairRunner.status === "OK") {
            setDeleteModalVisible(false);
            const index = props.state.value.findIndex(p => p.id === deleteQAPairRunner.runState.data.id);
            if (index < 0) return;
            props.state.setValue(
                props.state.value.splice(index, 1)
            );
            setSelectedItems([]);
        }
    }, [deleteQAPairRunner.status]);

    return (
        <div>
            <Cards
                header={
                    <Header
                        variant="h2"
                        counter={(getCounterString(selectedItems, filteredItems))}
                        actions={
                            <SpaceBetween size='xs' direction='horizontal'>
                                <Button
                                    disabled={selectedItems.length !== 1}
                                    onClick={() => setDeleteModalVisible(true)}
                                >Delete
                                </Button>
                                <Button
                                    disabled={selectedItems.length !== 1}
                                    onClick={() => {
                                        history.push(`/admin/${ props.branch.id }/view/${ selectedItems[0].id }`);
                                    }}>View Details
                                </Button>
                                <Button
                                    variant='primary'
                                    onClick={() => {
                                        history.push(`/admin/${ props.branch.id }/create`);
                                    }}>Create Q&A pairing
                                </Button>
                            </SpaceBetween>
                        }>
                            Q&A pairings
                    </Header>
                }
                cardDefinition={CARD_DEFINITION}
                items={filteredItems}
                onSelectionChange={e => setSelectedItems(e.detail.selectedItems)}
                selectedItems={selectedItems}
                empty={
                    <div>
                        <b>No QnAs to display</b>
                    </div>
                }
                selectionType="multi"
                filter={
                    <PropertyFilter
                        onChange={e => setQuery(e.detail)}
                        query={query}
                        i18nStrings={{
                            filteringAriaLabel: "your choice",
                            dismissAriaLabel: "Dismiss",
                            filteringPlaceholder: "Search",
                            groupValuesText: "Values",
                            groupPropertiesText: "Properties",
                            operatorsText: "Operators",
                            operationAndText: "and",
                            operationOrText: "or",
                            operatorLessText: "Less than",
                            operatorLessOrEqualText: "Less than or equal",
                            operatorGreaterText: "Greater than",
                            operatorGreaterOrEqualText:
                              "Greater than or equal",
                            operatorContainsText: "Contains",
                            operatorDoesNotContainText: "Does not contain",
                            operatorEqualsText: "Equals",
                            operatorDoesNotEqualText: "Does not equal",
                            editTokenHeader: "Edit filter",
                            propertyText: "Property",
                            operatorText: "Operator",
                            valueText: "Value",
                            cancelActionText: "Cancel",
                            applyActionText: "Apply",
                            allPropertiesLabel: "All properties",
                            tokenLimitShowMore: "Show more",
                            tokenLimitShowFewer: "Show fewer",
                            clearFiltersText: "Clear filters",
                            removeTokenButtonAriaLabel: () => "Remove token",
                            enteredTextLabel: text => `Use: "${text}"`
                        }}
                        hideOperations={true}
                        filteringProperties={[
                            PolarisFactory.PropertyFilter.TextProperty,
                            PolarisFactory.PropertyFilter.Category,
                        ]}
                        filteringOptions={PolarisFactory.PropertyFilter.toOptions(props.state.value)}
                    />
                }
            />
            { deleteModalVisible &&
                <Modal
                    onDismiss={() => setDeleteModalVisible(false)}
                    visible={true} 
                    header="Are you sure?"
                    footer={
                        <Box float='right'>
                            <SpaceBetween direction='horizontal' size='l'>
                                <Button
                                    variant='link'
                                    loading={deleteQAPairRunner.status === "Running"}
                                    onClick={() => setDeleteModalVisible(false)}>Cancel
                                </Button>
                                <Button
                                    variant='primary'
                                    loading={deleteQAPairRunner.status === "Running"}
                                    onClick={deleteQnA}>Confirm
                                </Button>
                            </SpaceBetween>
                        </Box>
                    }>
                    <TextContent>
                        <p>Once you confirm the following Q&A pairings will be deleted:</p>
                        <ul>
                            {
                                selectedItems.map((item) => {
                                    return (
                                        <li>{item.payload.question}</li>
                                    )
                                })
                            }
                        </ul>
                    </TextContent>
                </Modal>
            }
        </div>
    )
}