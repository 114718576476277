import { UserInputPanel } from './input-panel';
import React = require("react");
import { DisplayPanel } from './display-panel';
const awsLogo = require('../../assets/aws-logo.png').default;
const bceLogo = require('../../assets/branch-logos/bce.png').default;
import '../../style/body.scss'
import { Branch, Entity, EntityFactory, IQuery, KendraService, Query } from '@amzn/lincoln-chatbot-domain';
import { ChatbotOpenStatus, MsgSender } from './common';
import { UIModel, APIModel } from '../../model/general-models';
import { CustomHooks } from '../../utils/async-runner';
import { Conversation } from '../../utils/conversation-model';

export const ChatbotBody = (props: {
    branch: Entity<Branch.Data>;
    chatbotOpenState: UIModel.FieldState<ChatbotOpenStatus>
    onExpand?: () => void;
}) => {
    const conversationState = UIModel.FieldState.use<Conversation[]>({initialValue: []});
    const [userInput, setUserInput] = React.useState<string>("");
    const [currentQueryHistory, setCurrentQueryHistory] = React.useState<Entity<Query.Data>>();
    const searchKendraRunner = CustomHooks.useRunner(
        APIModel.search
    );
    const createQueryHistoryRunner = CustomHooks.useRunner(
        APIModel.createQuery
    );
    const updateQueryHistoryRunner = CustomHooks.useRunner(
        APIModel.updateQuery
    );
    const updateQueryFeedbackRunner = CustomHooks.useRunner(
        APIModel.updateQuery
    );

    const createOrUpdateQueryHistory = () => {
        if (searchKendraRunner.status !== "OK") return;
        if (
            !currentQueryHistory || // no history yet, create
            currentQueryHistory.payload.totalAttempts === 2 || // two attempts finished, starting new query
            currentQueryHistory.payload.feedback === "Thumb-up" // previous query completed with pos feedback
        ) {
            console.log(searchKendraRunner.runState.data);
            const userinput = searchKendraRunner.runState.data.userInput;
            const newHistory = Query.Factory.createHistoryData({
                userInput: searchKendraRunner.runState.data.userInput,
                result: searchKendraRunner.runState.data.queryResults,
                retry: 0
            });
            createQueryHistoryRunner.submitRun(
                IQuery.CreateQuery.Input.construct({
                    history: [newHistory],
                    branch: props.branch.id
                })
            );
        } else { // history already exists, update existing
            const newHistory = Query.Factory.createHistoryData({
                userInput: searchKendraRunner.runState.data.userInput,
                result: searchKendraRunner.runState.data.queryResults,
                retry: currentQueryHistory.payload.history.length
            });
            updateQueryHistoryRunner.submitRun(
                IQuery.UpdateQuery.Input.construct({
                    original: EntityFactory.toRef(currentQueryHistory),
                    newData: Query.Factory.updateQuery(currentQueryHistory.payload, {
                        newHistory: newHistory,
                        totalAttempts: currentQueryHistory.payload.history.length + 1
                    }),
                })
            );
        }
    }

    const searchKendra = () => {
        props.onExpand();
        const inquiryConv = new Conversation(MsgSender.User);
        inquiryConv.setContent(userInput);
        const convs = conversationState.value.slice();
        convs.push(inquiryConv);
        conversationState.setValue(convs);
        const numOfWords = userInput.split(' ')
        if (numOfWords.length < 3) {
            const errorConv = new Conversation(MsgSender.Lincoln)
            errorConv.setContent('Sorry I could not understand, please ask me the question again with more details')
            convs.push(errorConv)
            conversationState.setValue(convs);
        } else {
            searchKendraRunner.submitRun(KendraService.KendraSearch.Input.construct({
                queryText: userInput,
                branch: EntityFactory.toRef(props.branch)
            }));
        }
        setUserInput("");
    }

    React.useEffect(() => {
        const introConvs = [];
        {
            const introConv_1 = new Conversation(MsgSender.Lincoln);
            introConv_1.setContent(props.branch.payload.responses.greetings.openUpGreeting_1);
            introConv_1.disableLogo()
            introConvs.push(introConv_1);
        }
        {
            const introConv_2 = new Conversation(MsgSender.Lincoln);
            introConv_2.setContent(props.branch.payload.responses.greetings.openUpGreeting_2);
            introConvs.push(introConv_2);
        }
        conversationState.setValue(introConvs);
    }, []);

    const updateQueryFeedback = (feedback: Query.Feedback) => {
        const userFeedbackConv = new Conversation(MsgSender.User)
        userFeedbackConv.setContent(feedback);
        conversationState.setValue(
            [...conversationState.value, userFeedbackConv]
        );
        updateQueryFeedbackRunner.submitRun(
            IQuery.UpdateQuery.Input.construct({
                original: EntityFactory.toRef(currentQueryHistory),
                newData: Query.Factory.updateQuery(currentQueryHistory.payload, {
                    feedback: feedback,
                }),
            })
        );
    };

    React.useEffect(() => {
        if (searchKendraRunner.status === "OK") {
            createOrUpdateQueryHistory();
        } else if (searchKendraRunner.status === "Error") {
            processError();
        }
    }, [searchKendraRunner.status]);

    React.useEffect(() => {
        if (createQueryHistoryRunner.status === "OK") {
            setCurrentQueryHistory(createQueryHistoryRunner.runState.data);
            processAnswer(
                searchKendraRunner.runState.data,
                createQueryHistoryRunner.runState.data
            );
        }
    }, [createQueryHistoryRunner.status]);

    React.useEffect(() => {
        if (updateQueryHistoryRunner.status === "OK") {
            setCurrentQueryHistory(updateQueryHistoryRunner.runState.data);
            processAnswer(
                searchKendraRunner.runState.data,
                updateQueryHistoryRunner.runState.data
            );
        }
    }, [updateQueryHistoryRunner.status]);

    React.useEffect(() => {
        if (updateQueryFeedbackRunner.status === "OK") {
            const currentQuery = updateQueryFeedbackRunner.runState.data
            setCurrentQueryHistory(currentQuery);
            const feedbackConv = new Conversation(MsgSender.Lincoln)
            if (currentQuery.payload.feedback === "Thumb-up") {
                feedbackConv.setContent(props.branch.payload.responses.feedbackFollowUps.positiveFeedback);
            } else {
                if (currentQuery.payload.totalAttempts === 1) {
                    feedbackConv.setContent(props.branch.payload.responses.feedbackFollowUps.firstNegativeFeedback);
                }
                else if (currentQuery.payload.totalAttempts === 2) {
                    feedbackConv.setContent(props.branch.payload.responses.feedbackFollowUps.secondNegativeFeedback);
                }
            }
            conversationState.setValue([
                ...conversationState.value,
                feedbackConv
            ]);
        }
    }, [updateQueryFeedbackRunner.status]);

    const processError = () => {
        const conv = new Conversation(MsgSender.Lincoln);
        conv.setContent("Sorry I'm having some trouble getting an answer for you, could you try again?");
        conversationState.setValue(
            [...conversationState.value, conv]
        );
    }

    const processAnswer = (
        output: KendraService.KendraSearch.Output,
        currentQuery: Entity<Query.Data>
    ) => {
        const conv = new Conversation(MsgSender.Lincoln);
        if (output.totalNumberOfResults === 0) {
            if (currentQuery.payload.totalAttempts === 1) {
                conv.setContent(
                    props.branch.payload.responses.inProgressResponses.firstAnswerNotFoundResponse
                );
            } else if (currentQuery.payload.totalAttempts === 2) {
                conv.setContent(
                    props.branch.payload.responses.inProgressResponses.secondAnswerNotFoundResponse
                );
            }
            conversationState.setValue(
                [...conversationState.value, conv]
            );
        } else if (output.totalNumberOfResults === 1) {
            conv.setContent(output.queryResults[0].answer);
            conv.disableLogo();
            conv.displayFeedback();
            conversationState.setValue(
                [...conversationState.value, conv]
            );
        } else {
            conv.setContent(
                props.branch.payload.responses.inProgressResponses.multipleAnswersFoundResponse
            );
            conv.disableLogo();
            const convs = conversationState.value;
            convs.push(conv);
            conversationState.setValue(convs);
            for (const i in output.queryResults) {
                const result = output.queryResults[i];
                const subConv = new Conversation(MsgSender.Lincoln);
                if (parseInt(i) !== output.queryResults.length - 1) {
                    subConv.disableLogo()
                }
                subConv.setContent(result.question);
                subConv.addPendingAnswer(result.answer);
                convs.push(subConv)
                conversationState.setValue(convs);
            }
        }
    }

    const defaultFooter = () => {
        return (
            <div className={`chatbot-footer ${ props.chatbotOpenState.value === ChatbotOpenStatus.EXPANDED ? 'footer-expand': ''}`}>
                <p className='footer-by'>by</p>
                <img src={String(awsLogo)} draggable={false}/>
                <p className='footer-divider'>|</p>
                <p className='footer-legal'>Legal</p>
            </div>
        );
    }

    const footer = () => {
        if (props.branch.id === "bce") {
            return (
                <div className={`bce-footer ${ props.chatbotOpenState.value === ChatbotOpenStatus.EXPANDED ? 'bce-footer-expand': ''}`}>
                    <p className='footer-by'>by</p>
                    <img src={String(bceLogo)} draggable={false}/>
                </div>
            )
        } else {
            return defaultFooter();
        }
    }

    if (props.chatbotOpenState.value === ChatbotOpenStatus.FULLSIZE) {
        return <div> 
            <DisplayPanel
                chatbotOpenState={props.chatbotOpenState}
                isLoading={false}
                isProcessingAnswer={
                    searchKendraRunner.status === "Running" ||
                    updateQueryHistoryRunner.status === "Running" ||
                    createQueryHistoryRunner.status === "Running"
                }
                conversationState={conversationState}
                updateQueryFeedback={updateQueryFeedback}
            />
            <UserInputPanel
                chatbotOpenState={props.chatbotOpenState}
                isLoading={false}
                isProcessingAnswer={
                    searchKendraRunner.status === "Running" ||
                    updateQueryHistoryRunner.status === "Running" ||
                    createQueryHistoryRunner.status === "Running"
                }
                userInput={userInput}
                onInputChange={(userInput) => setUserInput(userInput)}
                onSearch={searchKendra}
            />
        </div>
    } else {
        return (
            <div className={`chatbot-body ${ props.chatbotOpenState.value === ChatbotOpenStatus.EXPANDED ? 'body-expand': ''}`}>
                {
                    props.chatbotOpenState.value === ChatbotOpenStatus.EXPANDED && 
                    <DisplayPanel
                        chatbotOpenState={props.chatbotOpenState}
                        isLoading={false}
                        isProcessingAnswer={
                            searchKendraRunner.status === "Running" ||
                            updateQueryHistoryRunner.status === "Running" ||
                            createQueryHistoryRunner.status === "Running"
                        }
                        conversationState={conversationState}
                        updateQueryFeedback={updateQueryFeedback}
                    />
                }
                <UserInputPanel
                    chatbotOpenState={props.chatbotOpenState}
                    isLoading={false}
                    isProcessingAnswer={
                        searchKendraRunner.status === "Running" ||
                        updateQueryHistoryRunner.status === "Running" ||
                        createQueryHistoryRunner.status === "Running"
                    }
                    userInput={userInput}
                    onInputChange={(userInput) => setUserInput(userInput)}
                    onSearch={searchKendra}
                />
                { footer() }
            </div>
        );
    }
    
}