export enum MsgSender {
    Lincoln,
    User
}

export enum ChatbotOpenStatus {
    CLOSED,
    OPEN,
    EXPANDED,
    FULLSIZE
}