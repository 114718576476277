import { APIInput, APIOutput, Branch, Entity, IBranch, Ref } from "@amzn/lincoln-chatbot-domain";
import { RestAPI } from "@aws-amplify/api-rest";

export interface BranchAPI {
    create(
        input: APIInput<IBranch.CreateBranch.Input>
    ): Promise<APIOutput<Entity<Branch.Data>>>;
    update(input: APIInput<IBranch.UpdateBranch.Input>): Promise<APIOutput<Entity<Branch.Data>>>;
    load(input: APIInput<Ref>): Promise<APIOutput<Entity<Branch.Data>>>;
    loadAll(input: APIInput<any>): Promise<APIOutput<Entity<Branch.Data>[]>>;
}

export class BranchAPIImpl implements BranchAPI {
    async update(input: APIInput<IBranch.UpdateBranch.Input>): Promise<APIOutput<Entity<Branch.Data>>> {
        let myInit = {
            body: input,
            headers: {},
        };
        let response: APIOutput<Entity<Branch.Data>> = await RestAPI.post(
            "branch",
            "/branch/update",
            myInit
        );
        return response;
    }
    
    async create(input: APIInput<IBranch.CreateBranch.Input>) {
        let myInit = {
            body: input,
            headers: {},
        };
        let response: APIOutput<Entity<Branch.Data>> = await RestAPI.post(
            "branch",
            "/branch/create",
            myInit
        );
        return response;
    }

    async load(input: APIInput<Ref>): Promise<APIOutput<Entity<Branch.Data>>> {
        let myInit = {
            body: input,
            headers: {},
        };
        let response: Promise<APIOutput<Entity<Branch.Data>>> = await RestAPI.post(
            "branch",
            "/branch/load",
            myInit
        );
        return response;
    }
    
    async loadAll(input: APIInput<any>) {
        let myInit = {
            body: input,
            headers: {},
        };
        let response: APIOutput<Entity<Branch.Data>[]> = await RestAPI.post(
            "branch",
            "/branch/loadAll",
            myInit
        );
        return response;
    }
}