import { APIInput, APIOutput, Entity, IQuestionAnswerPair, QuestionAnswerPair, Ref } from "@amzn/lincoln-chatbot-domain";
import { RestAPI } from "@aws-amplify/api-rest";

export interface QuestionAnswerPairAPI {
    create(
        input: APIInput<IQuestionAnswerPair.AddQuestionAnswerPair.Input>
    ): Promise<APIOutput<Entity<QuestionAnswerPair.Data>>>;
    update(
        input: APIInput<IQuestionAnswerPair.UpdateQuestionAnswerPair.Input>
    ): Promise<APIOutput<Entity<QuestionAnswerPair.Data>>>;
    load(
        input: APIInput<Ref>
    ): Promise<APIOutput<Entity<QuestionAnswerPair.Data>>>;
    loadByBranch(
        input: APIInput<Ref>
    ): Promise<APIOutput<Entity<QuestionAnswerPair.Data>[]>>;
    delete(
        input: APIInput<Ref>
    ): Promise<APIOutput<Entity<QuestionAnswerPair.Data>>>;
}

export class QuestionAnswerPairAPIImpl implements QuestionAnswerPairAPI {
    async loadByBranch(input: APIInput<Ref>): Promise<APIOutput<Entity<QuestionAnswerPair.Data>[]>> {
        let myInit = {
            body: input,
            headers: {},
        };
        let response: APIOutput<Entity<QuestionAnswerPair.Data>[]> = await RestAPI.post(
            "question-answer-pair",
            "/question-answer-pair/loadByBranch",
            myInit
        );
        return response;
    }

    async load(input: APIInput<Ref>): Promise<APIOutput<Entity<QuestionAnswerPair.Data>>> {
        let myInit = {
            body: input,
            headers: {},
        };
        let response: APIOutput<Entity<QuestionAnswerPair.Data>> = await RestAPI.post(
            "question-answer-pair",
            "/question-answer-pair/load",
            myInit
        );
        return response;
    }

    async create(
        input: APIInput<IQuestionAnswerPair.AddQuestionAnswerPair.Input>
    ): Promise<APIOutput<Entity<QuestionAnswerPair.Data>>> {
        let myInit = {
            body: input,
            headers: {},
        };
        let response: APIOutput<Entity<QuestionAnswerPair.Data>> = await RestAPI.post(
            "question-answer-pair",
            "/question-answer-pair/create",
            myInit
        );
        return response;
    }

    async update(
        input: APIInput<IQuestionAnswerPair.UpdateQuestionAnswerPair.Input>
    ): Promise<APIOutput<Entity<QuestionAnswerPair.Data>>> {
        let myInit = {
            body: input,
            headers: {},
        };
        let response: APIOutput<Entity<QuestionAnswerPair.Data>> = await RestAPI.post(
            "question-answer-pair",
            "/question-answer-pair/update",
            myInit
        );
        return response;
    }

    async delete(
        input: APIInput<Ref>
    ): Promise<APIOutput<Entity<QuestionAnswerPair.Data>>> {
        let myInit = {
            body: input,
            headers: {},
        };
        let response: APIOutput<Entity<QuestionAnswerPair.Data>> = await RestAPI.post(
            "question-answer-pair",
            "/question-answer-pair/delete",
            myInit
        );
        return response;
    }
}