import { Builder } from "builder-pattern";
import { PrimitiveDataFactory } from "../general/data-factory";
import { Identity } from "../general/identity";
import { EntityType } from "./entity-type";
export class Entity<T> {
    id: string;
    type: EntityType;
    creationDate: Date;
    createdBy: Identity;
    lastModifiedBy: Identity;
    lastModifiedDate: Date;
    lastModifiedAction: Action;
    version: number;
    payload: T;
    name: string;
    gsiIndex?: string;
}

type Action = "Create" | "Update" | "Update general info" | "Update payload";

export interface Ref {
    id: string;
    type: EntityType;
    version: number;
}

export class EntityFactory {
    static toRef(entity: Entity<any>) {
        return Builder<Ref>()
            .id(entity.id)
            .type(entity.type)
            .version(entity.version)
            .build();
    }

    static create<T>(params: {
        id?: string;
        name?: string;
        identity: Identity;
        type: EntityType;
        payload: T;
        gsiIndex?: string;
    }): Entity<T> {
        const id = PrimitiveDataFactory.id();
        const now = new Date();
        const builder = Builder<Entity<T>>()
            .type(params.type)
            .payload(params.payload)
            .creationDate(now)
            .lastModifiedDate(now)
            .createdBy(params.identity)
            .lastModifiedBy(params.identity)
            .lastModifiedAction("Create")
            .name("No name")
            .version(1)
            .gsiIndex(params.gsiIndex)
            .id(id);
        if (params.id) {
            builder.id(params.id);
        }
        if (params.name) {
            builder.name(params.name);
        }
        return builder.build();
    }
    static update<T>(entity: Entity<T>, newData: {
        name?: string;
        payload?: T
        identity: Identity;
    }): Entity<T> {
        if (!newData.name && !newData.payload) {
            return entity;
        }
        const now = new Date();
        const builder = Builder<Entity<any>>(entity)
            .lastModifiedDate(now)
            .lastModifiedBy(newData.identity)
            .lastModifiedAction("Update")
            .version(entity.version + 1)
        if (newData.name) {
            builder.name(newData.name);
        }
        if (newData.payload) {
            builder.payload(newData.payload);
        }
        return builder.build();
    }

    static updateGeneralInfo(entity: Entity<any>, newData: {
        name?: string;
        identity: Identity;
    }): Entity<any> {
        const now = new Date();
        const builder = Builder<Entity<any>>(entity)
            .lastModifiedDate(now)
            .lastModifiedBy(newData.identity)
            .lastModifiedAction("Update general info")
            .version(entity.version + 1)
        if (newData.name) {
            builder.name(newData.name);
        }
        return builder.build();
    }

    static updatePayload<T>(entity: Entity<T>, newData: {
        identity: Identity,
        payload: T
    }) {
        const now = new Date();
        const builder = Builder<Entity<any>>(entity)
            .lastModifiedDate(now)
            .lastModifiedBy(newData.identity)
            .lastModifiedAction("Update payload")
            .payload(newData.payload)
            .version(entity.version + 1)
        return builder.build();
    }
}