import { Builder } from "builder-pattern"
import { Entity, Ref } from "../../domains/entity";
import { QuestionAnswerPair } from "../../domains/payload-object/question-answer-pair";
import { APIInput, APIOutput } from "../../general/api";

export namespace IQuestionAnswerPair {
    export interface API {
        load(input: APIInput<Ref>):
            Promise<APIOutput<Entity<QuestionAnswerPair.Data>>>;
        addQuestionAnswerPair(
            input: APIInput<AddQuestionAnswerPair.Input>
        ): Promise<APIOutput<Entity<QuestionAnswerPair.Data>>>;
        deleteQuestionAnswerPair(
            input: APIInput<Ref>
        ): Promise<APIOutput<Entity<QuestionAnswerPair.Data>>>;
        updateQuestionAnswerPair(
            input: APIInput<UpdateQuestionAnswerPair.Input>
        ): Promise<APIOutput<Entity<QuestionAnswerPair.Data>>>;
        getAllQuestionAnswerPairs():
            Promise<APIOutput<Entity<QuestionAnswerPair.Data>[]>>;
        getAllQuestionAnswerPairsFromBranch(
            input: APIInput<Ref>
        ): Promise<APIOutput<Entity<QuestionAnswerPair.Data>[]>>;
    }

    export namespace AddQuestionAnswerPair{
        export class Input {
            questionContent: string;
            branchRef: Ref;
            textAnswer: string;
            audioAnswer: string;
            category: string;
            tags: string[];
            private constructor() {}

            static construct(params: {
                questionContent: string;
                branchRef: Ref;
                textAnswer: string;
                audioAnswer: string;
                category: string;
                tags?: string[]
            }): Input  {
                return Builder<Input>(new Input())
                    .questionContent(params.questionContent)
                    .branchRef(params.branchRef)
                    .textAnswer(params.textAnswer)
                    .audioAnswer(params.audioAnswer)
                    .category(params.category)
                    .tags(params.tags ? params.tags : [])
                    .build()
            }
        }
    }

    export namespace DeleteQuestionAnswerPair {
        export class Input {
            branchRef: Ref;
            pairRefs: Ref[];

            private constructor() {}

            static construct(params: {
                branchRef: Ref;
                questionRefs: Ref[];
            }): Input {
                return Builder<Input>(new Input())
                    .branchRef(params.branchRef)
                    .pairRefs(params.questionRefs)
                    .build();
            }

        }
    }

    export namespace UpdateQuestionAnswerPair {
        export class Input {
            original: Ref;
            newData: QuestionAnswerPair.Data;

            private constructor() {}

            static construct(params: {
                original: Ref;
                newData: QuestionAnswerPair.Data;
            }): Input {
                return Builder<Input>(new Input())
                    .original(params.original)
                    .newData(params.newData)
                    .build();
            }
        }
    }
}