import * as React from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import { BreadcrumbGroup } from "@amzn/awsui-components-react"
import { AuthPage } from "./webpages/AuthPageForIFrame";
import { ManageHubHomePage } from "./webpages/ManageHubHomePage";
import { EntityType, Ref } from "@amzn/lincoln-chatbot-domain";
import { Builder } from "builder-pattern";
import { ManageHubBranchPage } from "./webpages/BranchDetail";
import { QuestionAnswerPairCreate } from "./webpages/QuestionAnswerPairCreate";
import { QuestionAnswerPairDetail } from "./webpages/QuestionAnswerPairDetail";
import { Chatbot } from "./webpages/Chatbot";
import { BranchCreate } from "./webpages/BranchCreate";
import { WelcomePage } from "./webpages/WelcomePage";

export const routes = [
    {
        path: '/',
        content: (props: RouteComponentProps) => <WelcomePage/>,
        contentType: 'default',
        maxContentWidth: 1600
    },
    {
        path: '/chatbot/:branchId',
        content: (props: RouteComponentProps) => {
            let { branchId } = useParams<{ branchId: string }>();
            return <Chatbot branchId={branchId} authBypassMode={false}/>;
        },
        contentType: 'default',
        maxContentWidth: 1600
    },
    {
        path: '/chatbot/:branchId/iframe',
        content: (props: RouteComponentProps) => {
            let { branchId } = useParams<{ branchId: string }>();
            return <Chatbot branchId={branchId} authBypassMode={true}/>
        },
        contentType: 'default',
        maxContentWidth: 1600
    },
    {
        path: '/auth',
        content: (props: RouteComponentProps) => <AuthPage/>,
        contentType: 'default',
        maxContentWidth: 1600
    },
    {
        path: '/branch/create',
        content: (props: RouteComponentProps) => {
            return <BranchCreate/>
        },
        contentType: 'default',
        maxContentWidth: 1600
    },
    {
        path: "/admin/:branchId",
        content: (props: RouteComponentProps) => {
            let { branchId } = useParams<{ branchId: string }>();
            const branchRef = Builder<Ref>().id(branchId).type(EntityType.Branch).build();
            console.log("branch: ", branchRef);
            return <ManageHubBranchPage branchRef={Builder<Ref>().id(branchId).type(EntityType.Branch).build()}/>;
        },
        breadcrumbItems: () => {
            let { branchId } = useParams<{ branchId: string }>();
            return (
                <BreadcrumbGroup
                    ariaLabel='Breadcrumbs'
                    items={[
                        { text: 'Q&A Pairing', href: `#/admin/${ branchId }`}
                    ]}
                />
            )
        },
        contentType: "default",
        maxContentWidth: 1600
    },
    {
        path: "/admin/:branchId/create",
        breadcrumbItems: () => {
            let { branchId } = useParams<{ branchId: string }>();
            return (
                <BreadcrumbGroup
                    ariaLabel='Breadcrumbs'
                    items={[
                        { text: 'Home', href: '#/admin' },
                        { text: 'Q&A Pairing', href: `#/admin/${ branchId }`},
                        { text: 'Create', href: `#/admin/${ branchId }/create`}
                    ]}
                />
            )
        },
        content: (matchProps) => {
            let { branchId } = useParams<{ branchId: string }>();
            return <QuestionAnswerPairCreate branchId={branchId}/>
        },
        contentType: "default",
        maxContentWidth: 1600
    },
    // {
    //     path: "/admin/:branchId/update/:qaId",
    //     breadcrumbItems: () => {
    //         let { branchId } = useParams<{ branchId: string }>();
    //         return (
    //             <BreadcrumbGroup
    //                 label='Breadcrumbs'
    //                 items={[
    //                     { text: 'Home', href: '#/admin' },
    //                     { text: 'Q&A Pairing', href: `#/admin/${ branchId }`},
    //                     { text: 'Update', href: `#/admin/${ branchId }/update`}
    //                 ]}
    //             />
    //         )
    //     },
    //     content: (matchProps) => {
    //         let { branchId, qaId } = useParams<{ branchId: string; qaId: string }>();
    //         return <QADetail displayMode={DisplayMode.Edit} branchId={branchId} qaId={qaId}/>
    //     },
    //     contentType: "default",
    //     maxContentWidth: 1600
    // },
    {
        path: "/admin/:branchId/view/:qaId",
        breadcrumbItems: () => {
            let { branchId } = useParams<{ branchId: string }>();
            return (
                <BreadcrumbGroup
                    ariaLabel='Breadcrumbs'
                    items={[
                        { text: 'Home', href: '#/admin' },
                        { text: 'Q&A Pairing', href: `#/admin/${ branchId }`},
                        { text: 'View', href: `#/admin/${ branchId }/view`}
                    ]}
                />
            )
        },
        content: (matchProps) => {
            let { branchId, qaId } = useParams<{ branchId: string; qaId }>();
            return <QuestionAnswerPairDetail branchId={branchId} qaId={qaId}/>
        },
        contentType: "default",
        maxContentWidth: 1600
    }
]
