import { Builder } from "builder-pattern";
import { APIInput, APIOutput } from "../..";
import { Ref } from "../../domains/entity";

export namespace KendraService {
    export interface IGateway {
        search(input: APIInput<KendraSearch.Input>): Promise<APIOutput<KendraSearch.Output>>
        indexBranch(branchRef: Ref): void;
    }

    export namespace KendraSearch {
        export type ConfidenceScore = "VERY_HIGH" | "HIGH" | "MEDIUM" | "LOW" | string;
        export class Input {
            queryText: string;
            branch: Ref;

            private constructor() {}
            public static construct(params: {
                queryText: string,
                branch: Ref
            }) {
                return Builder<Input>(new Input())
                    .branch(params.branch)
                    .queryText(params.queryText)
                    .build();
            }
        }

        export class Result {
            id: string;
            question: string;
            answer: string;
            feedbackToken: string;
            score: ConfidenceScore;

            public static construct(params: {
                id: string;
                question: string;
                answer: string;
                feedbackToken: string;
                score: ConfidenceScore;
            }): Result {
                return Builder<Result>()
                    .id(params.id)
                    .question(params.question)
                    .answer(params.answer)
                    .feedbackToken(params.feedbackToken)
                    .score(params.score)
                    .build();
            }
        }

        export class Output {
            userInput: string;
            totalNumberOfResults: number;
            queryResults: Result[]

            private constructor() {}
            public static construct(params: {
                userInput: string;
                totalNumberOfResults: number;
                queryResults: Result[]
            }): Output {
                return Builder<Output>()
                    .userInput(params.userInput)
                    .totalNumberOfResults(params.totalNumberOfResults)
                    .queryResults(params.queryResults)
                    .build();
            }
        }
    }
}