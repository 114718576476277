import { Entity, EntityFactory, IQuestionAnswerPair, QuestionAnswerPair, Ref } from "@amzn/lincoln-chatbot-domain";
import { Builder } from "builder-pattern";
import { useEffect } from "react";
import { UIModel } from "./general-models";

export namespace QuestionAnswerPairModel {
    export namespace UI {
        export class CreateState {
            questionContentField: UIModel.FieldState<string>;
            branchRefField: UIModel.FieldState<Ref>;
            textAnswerField: UIModel.FieldState<string>;
            audioAnswerField: UIModel.FieldState<string>;
            categoryField: UIModel.FieldState<string>;
            reset: () => void;

            static toInput(state: CreateState): IQuestionAnswerPair.AddQuestionAnswerPair.Input {
                return IQuestionAnswerPair.AddQuestionAnswerPair.Input.construct({
                    questionContent: state.questionContentField.value,
                    branchRef: state.branchRefField.value,
                    textAnswer: state.textAnswerField.value,
                    audioAnswer: state.audioAnswerField.value,
                    category: state.categoryField.value,
                });
            }

            static use(props: {
                template?: IQuestionAnswerPair.AddQuestionAnswerPair.Input
            }): CreateState {
                const questionContentField = UIModel.FieldState.use<string>({initialValue: ""});
                const branchRefField = UIModel.FieldState.use<Ref>({});
                const textAnswerField = UIModel.FieldState.use<string>({initialValue: ""});
                const audioAnswerField = UIModel.FieldState.use<string>({initialValue: ""});
                const categoryField = UIModel.FieldState.use<string>({initialValue: ""});

                const reset = () => {
                    questionContentField.setValue("");
                    textAnswerField.setValue("");
                    audioAnswerField.setValue("");
                    categoryField.setValue("");
                };

                useEffect(() => {
                    if (!props.template) return;
                    questionContentField.setValue(props.template.questionContent);
                    branchRefField.setValue(props.template.branchRef);
                    textAnswerField.setValue(props.template.textAnswer);
                    audioAnswerField.setValue(props.template.audioAnswer);
                    categoryField.setValue(props.template.category);
                }, []);

                return Builder<CreateState>()
                    .questionContentField(questionContentField)
                    .audioAnswerField(audioAnswerField)
                    .textAnswerField(textAnswerField)
                    .branchRefField(branchRefField)
                    .categoryField(categoryField)
                    .reset(reset)
                    .build();
            }
        }

        export class UpdateState {
            originalRefField: UIModel.FieldState<Ref>;
            newEntityDataField: UIModel.FieldState<QuestionAnswerPair.Data>;
            update: (params: { question?: string, answer?: string, category?: string }) => void;
            reset: () => void;

            static toInput(
                state: UpdateState
            ): IQuestionAnswerPair.UpdateQuestionAnswerPair.Input {
                return IQuestionAnswerPair.UpdateQuestionAnswerPair.Input.construct({
                    original: state.originalRefField.value,
                    newData: state.newEntityDataField.value,
                });
            }

            static use(props: { originalEntity: Entity<QuestionAnswerPair.Data> }): UpdateState {
                const originalRefField = UIModel.FieldState.use<Ref>({
                    initialValue: EntityFactory.toRef(props.originalEntity)
                });
                const newEntityDataField = UIModel.FieldState.use<QuestionAnswerPair.Data>({
                    initialValue: props.originalEntity.payload
                });

                const reset = () => {
                    originalRefField.setValue(EntityFactory.toRef(props.originalEntity));
                    newEntityDataField.setValue(props.originalEntity.payload);
                }

                const update = (params: {
                    question?: string,
                    answer?: string,
                    category?: string,
                }) => {
                    const newQuestionAnswerPair = QuestionAnswerPair.Factory.update(
                        newEntityDataField.value,
                        {
                            question: params.question,
                            answer: params.answer,
                            category: params.category,
                        }
                    );
                    newEntityDataField.setValue(newQuestionAnswerPair);
                }

                return Builder<UpdateState>()
                    .newEntityDataField(newEntityDataField)
                    .originalRefField(originalRefField)
                    .update(update)
                    .reset(reset)
                    .build();
            }
        }
    }
}