import { APIInput, APIOutput, Entity, KendraService, QuestionAnswerPair } from "@amzn/lincoln-chatbot-domain";
import { RestAPI } from "@aws-amplify/api-rest";

export interface KendraSearchAPI {
    query(
        input: APIInput<KendraService.KendraSearch.Input>
    ): Promise<any>;
}

export class KendraSearchAPIImpl implements KendraSearchAPI {
    async query(
        input: APIInput<KendraService.KendraSearch.Input>
    ): Promise<any> {
        let myInit = {
            body: input,
            headers: {},
        };
        let response: APIOutput<Entity<QuestionAnswerPair.Data>> = await RestAPI.post(
            "kendra",
            "/kendra/search",
            myInit
        );
        return response;
    }
}